import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { AdminTrainingStep, TrainingStep } from "src/app/core/data/models/PartnerRegistration";
import { AppState } from "src/app/shared/services/app-state";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { AdminService } from "src/app/shared/services/admin.service";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { type ManufacturerInvitation } from "src/app/core/data/models/ManufacturerInvitation";
import { type Company, type User } from "./edit-user.models";
import { type ManufacturerProfile } from "src/app/core/store/app.models";
import { MessageService } from "primeng/api";
import {
  faEnvelope,
  faToggleOn,
  faToggleOff,
  faTrash,
  faCheck,
  faTimes,
  faCrown,
  faUser,
  faQuestion,
  faSave,
  faInfoCircle,
  faBriefcase,
  faBuilding,
  faHandshakeAlt,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  userId: number;
  user: User;
  companyId: number = this.appState.currentProfile.manufacturer.id;
  private sub: any;
  roleSelectedOption: string;
  selectedCompany: Company;
  userRelatedCompanies: Company[] = [];
  userForm: FormGroup;

  faEnvelope = faEnvelope;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faTrash = faTrash;
  faCheck = faCheck;
  faTimes = faTimes;
  faCrown = faCrown;
  faUser = faUser;
  faQuestion = faQuestion;
  faSave = faSave;
  faInfoCircle = faInfoCircle;
  faBriefcase = faBriefcase;
  faBuilding = faBuilding;
  faHandshakeAlt = faHandshakeAlt;
  faScrewdriverWrench = faScrewdriverWrench;

  trainingSteps = [
    { label: "Not Started", value: TrainingStep.NotStarted },
    {
      label: "A - RP Onboarding Invited",
      value: TrainingStep.RPOnboardInviteSent,
    },
    {
      label: "B - RP Onboarding Scheduled",
      value: TrainingStep.RPOnboardScheduled,
    },
    {
      label: "C - RP Onboarding Initiated",
      value: TrainingStep.RPOnboardInitiated,
    },
    {
      label: "D - RP Onboarding Complete",
      value: TrainingStep.RPOnboardComplete,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private appState: AppState,
    public spinner: SpinnerService,
    private adminService: AdminService,
    private profileService: ProfileService,
    private confirmService: ConfirmService,
    private messageService: MessageService,
    private router: Router,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    // Initialize the form
    this.userForm = this.fb.group({
      firstName: [{ value: "", disabled: !this.isRPAdmin }, Validators.required],
      middleName: [{ value: "", disabled: !this.isRPAdmin }],
      lastName: [{ value: "", disabled: !this.isRPAdmin }, Validators.required],
      email: [{ value: "", disabled: true }, [Validators.required, Validators.email]],
      phone: [{ value: "", disabled: !this.isRPAdmin }, Validators.required],
      trainingStatusId: [""],
      adminTrainingStatusId: [""],
      roles: [""],
    });

    this.sub = this.route.params.subscribe((params) => {
      this.userId = +params["id"];
      this.setSelectedUser(this.userId);
    });

    this.appState.userSelection.subscribe((data) => {
      if (data) {
        this.user = data;
        this.populateForm(data);
      }
    });

    this.getRelatedCompanies();
  }

  getRelatedCompanies() {
    this.profileService.getAffiliatedCompanies(this.userId).subscribe((companies) => {
      if (companies) {
        this.userRelatedCompanies = companies;
      }
    });
  }

  setSelectedUser(userId: number) {
    this.adminService
      .getUsersByManufacturerId(this.appState.currentProfile.manufacturer.id)
      .pipe(
        tap((response: any) => {
          if (response) {
            const user = response.find((user) => user.id === userId);
            if (!user) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: `Can find user with ID ${userId}`,
              });
              return;
            }
            this.appState.selectedUser(user);
          }
        })
      )
      .subscribe();
  }

  populateForm(data: any) {
    const nameParts = data.fullName.split(" ");
    const firstName = nameParts[0] || "";
    const middleName = nameParts.length > 2 ? nameParts.slice(1, -1).join(" ") : "";
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    const trainingStatus = this.trainingSteps.find((step) => step.value === data.trainingStatusId);

    this.userForm.patchValue({
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: data.email ?? "",
      phone: data.phoneNumber ?? "",
      trainingStatusId: trainingStatus ? trainingStatus.value : "",
      roles: data.roles,
      companies: data.companies,
    });

    this.roleSelectedOption = data.roles;
  }

  isUserInactive(): boolean {
    return this.user.status === "Inactive";
  }

  resendRPInvitation() {
    const invite: ManufacturerInvitation = {
      companyId: this.companyId,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      companyName: this.user.companyName,
      contactPhoneNumber: this.user.phoneNumber,
      email: this.user.email,
    };

    this.profileService
      .resendRPInvitation(invite)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation resent successfully!",
          });
          return EMPTY;
        }),
        catchError((error: HttpErrorResponse) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.message ?? "Invitation resent error. Please try again.",
          });
          return EMPTY;
        })
      )
      .subscribe();
  }

  addRoofingWRX() { }

  deactivateUser() {
    const data = {
      userId: this.user.id,
      manufacturerId: this.companyId,
      email: this.user.email,
    };
    const userToDeactivate = [data];

    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to make selected user inactive?",
      "pi pi-question-circle",
      () => {
        this.profileService
          .deactivateUser(userToDeactivate)
          .pipe(
            tap((_profile: ManufacturerProfile) => {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "User was successfully deactivated",
              });
              return EMPTY;
            }),
            catchError((error: HttpErrorResponse) => {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: error.message ?? "Deactivate user error.",
              });
              return EMPTY;
            })
          )
          .subscribe();
      }
    );
  }

  handleCancelClick() {
    this.router.navigate(["/admin/user-management"]);
  }

  selectRoleOption(newRole: string) {
    let updatedRoles = this.user.roles.split(",").map((role) => role.trim());

    if (newRole === "Normal" && updatedRoles.includes("RPAdmin")) {
      updatedRoles = updatedRoles.filter((role) => role !== "RPAdmin");
      updatedRoles.push("Normal");
    } else if (newRole === "RPAdmin") {
      if (updatedRoles.includes("Normal")) {
        updatedRoles = updatedRoles.filter((role) => role !== "Normal");
      }
      if (!updatedRoles.includes("RPAdmin")) {
        updatedRoles.push("RPAdmin");
      }
    }

    this.roleSelectedOption = updatedRoles.join(", ");
  }

  saveUser() {
    const { firstName, middleName, lastName, phone, trainingStatusId, adminTrainingStatusId } = this.userForm.value;

    const trainingStatus = this.trainingSteps.find((step) => step.value === trainingStatusId);

    const updatedUser: User = {
      ...this.user,
      fullName: `${firstName || ""} ${middleName || ""} ${lastName || ""}`,
      firstName,
      phoneNumber: phone,
      trainingStatus: trainingStatus.label,
      trainingStatusId,
      adminTrainingStatusId,
      roles: this.roleSelectedOption,
    };

    console.log("User Saved!", updatedUser);
  }
}
