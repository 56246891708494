<p-accordion *ngIf="!completedStep[0] || !completedStep[1] || !completedStep[2]" [multiple]="false">
  <!-- Step 1 -->
  <step-card
    id="jobStep_1"
    number="1"
    label="Data source"
    [selected]="activeStep[0]"
    [textSelected]="'Pick the method you want to use to obtain dimensions data:'"
    [completed]="completedStep[0]"
    [textCompleted]="getTextCompletedStep1()"
    [iconCompleted]="iconCompleted"
  >
    <h2 class="srOnly">Data source</h2>

    <section class="optionsCont mb-4">
      <h3 class="srOnly">Select source</h3>
      <option-box
        [id]="'jobDataSourceOption_1'"
        name="jobDataSource"
        value="option1"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(1)"
        [checked]="step1SelectedOption === 'option1'"
        [icon]="faLocationDot"
        label="Address"
      >
        <span>Obtain dimensions data from any location based on accurate aerial views provided by EagleView.</span>
      </option-box>
      <option-box
        [id]="'jobDataSourceOption_2'"
        name="jobDataSource"
        value="option2"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(2)"
        [icon]="faFile"
        label="XML File"
      >
        <span>Bring your own XML roofing geometry file from a third party.</span>
      </option-box>
      <option-box
        [id]="'jobDataSourceOption_3'"
        name="jobDataSource"
        value="option3"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(3)"
        [icon]="faMap"
        label="Blueprints"
        subtitle="& other documents"
      >
        <span
          >Upload any plans and documents related to your project. This data source is only supported by Roofing
          WRX.</span
        >
      </option-box>
    </section>

    <section class="grid" *ngIf="step1SelectedOption === 'option1' && isEagleViewActive">
      <h3 class="srOnly">Address settings</h3>
      <div class="col-12 md:col-6" [formGroup]="newJobLocation">
        <div class="inputCont">
          <label for="jobInput_jobName1" class="label">Job name</label>
          <input
            pInputText
            id="jobInput_jobName1"
            type="text"
            name="name"
            class="w-full"
            placeholder="Job name"
            formControlName="name"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_jobType1" class="label">Job Type</label>
          <div class="flex column-gap-3">
            <p-dropdown
              [options]="jobTypes"
              optionLabel="name"
              class="w-full"
              placeholder="Select job type"
              formControlName="jobType"
              optionValue="name"
              id="jobInput_jobType1"
              [disabled]="true"
            ></p-dropdown>
            <app-info-button pTooltip="About job types" (click)="toggleDemoAlert('jobType', $event)"></app-info-button>
          </div>
          <alert
            *ngIf="demoAlerts.jobType"
            color="main"
            [icon]="faCircleInfo"
            label="About job types"
            description="Lorem ipsum"
            styleClass="mt-3"
          ></alert>
        </div>
        <div class="inputCont">
          <label for="jobInput_address" class="label">Address</label>
          <input
            pInputText
            id="jobInput_address"
            type="text"
            name="middletName"
            class="w-full"
            placeholder="Address"
            formControlName="address"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_state" class="label">State/province</label>
          <p-dropdown
            [options]="stateList"
            optionLabel="name"
            class="w-full"
            placeholder="Select state"
            formControlName="state"
            optionValue="name"
            id="jobInput_state"
            optionValue="abbreviation"
            [disabled]="true"
          ></p-dropdown>
        </div>
        <div class="inputCont">
          <label for="jobInput_city" class="label">City</label>
          <input
            pInputText
            id="jobInput_city"
            class=""
            type="text"
            name="middletName"
            class="w-full"
            placeholder="City"
            formControlName="city"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_ZIPCode" class="label">ZIP code</label>
          <div class="flex column-gap-3">
            <input
              pInputText
              id="jobInput_ZIPCode"
              type="text"
              name="middletName"
              formControlName="zip"
              class="w-full max-w-10rem"
              placeholder="ZIP code"
              readonly
            />
            <app-info-button pTooltip="About Address" (click)="toggleDemoAlert('address', $event)"></app-info-button>
          </div>
          <alert
            *ngIf="demoAlerts.address"
            color="main"
            [icon]="faCircleInfo"
            label="Address"
            description="By entering any address covered by Eagleview (as of now, the entirety of the US and Canada), the system will automatically identify any roof in that location and get measurement data."
            styleClass="mt-3"
          ></alert>
        </div>
        <div class="inputCont flex">
          <p-checkbox
            inputId="jobInput_changedRecentCheck"
            name="groupname"
            [binary]="true"
            formControlName="changesInLast4Years"
          ></p-checkbox>
          <label for="jobInput_changedRecentCheck" class="label_check mr-3"> Changed in the last 4 years </label>
          <app-info-button
            pTooltip="About Updated data"
            (click)="toggleDemoAlert('updatedData', $event)"
          ></app-info-button>
        </div>
        <alert
          *ngIf="demoAlerts.updatedData"
          color="main"
          [icon]="faCircleInfo"
          label="Updated data"
          description="In cases Eagleview data might be outdated, you can request Eagleview to perform a manual update of your location."
          styleClass="mb-3"
        ></alert>
        <button pButton type="button" class="outline" (click)="findAddress()">
          <fa-icon [icon]="faSearch"></fa-icon>
          Locate address
        </button>
      </div>
      <div class="col-12 md:col-6">
        <iframe
          title="eagleViewMap"
          id="pictometry_ipa"
          [src]="pictometryUrl | safe: 'resourceUrl'"
          height="100%"
          width="100%"
          style="border: 0px"
        ></iframe>
      </div>
      <div class="col-12 md:col-6 ml-auto flex flex-column align-items-end">
        <app-info-button pTooltip="About EagleView" (click)="toggleDemoAlert('eagleViewMap', $event)"></app-info-button>
        <alert
          *ngIf="demoAlerts.eagleViewMap"
          color="main"
          [icon]="faCircleInfo"
          label="EagleView"
          description="The map provides you with an accurate aerial view of your job location."
          styleClass="mt-3"
        ></alert>
      </div>
    </section>

    <section *ngIf="step1SelectedOption === 'option1' && !isEagleViewActive">
      <h3 class="srOnly">Address settings</h3>
      <app-service-item
        [label]="'EagleView'"
        [description]="
          'You need an EagleView account in order to use addresses as a data source. Please create or link an existing EagleView account to continue, or try using a different data source.'
        "
        [learnMoreLink]="'link'"
        [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
        [logo]="'assets/img/icons/source_ev.png'"
        [isServiceAvailable]="true"
        [isBanner]="true"
        [isDemo]="true"
        [infoTooltip]="'About EagleView Account and Billing'"
        [infoAlertTitle]="'About EagleView Account and Billing'"
        [infoAlertDescription]="'Lorem ipsum'"
        (infoButtonClick)="toggleDemoAlert('eagleViewAccountAndBilling', $event)"
      >
      </app-service-item>
    </section>

    <section *ngIf="step1SelectedOption === 'option2'">
      <h3 class="srOnly">XML settings</h3>
      <div class="grid">
        <div class="col-12 md:col-6" [formGroup]="newJobXML">
          <div class="inputCont">
            <label for="jobInput_jobName2" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName2"
              type="text"
              name="jobName"
              class="w-full"
              formControlName="name"
              placeholder="Job name"
              (blur)="nameValidation('XML')"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName2"
              *ngIf="xmlFormJobName.invalid && (xmlFormJobName.touched || xmlFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a job name
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType2" class="label">Job Type</label>
            <div class="flex column-gap-3">
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType2"
                optionValue="name"
              ></p-dropdown>
              <app-info-button
                pTooltip="About job types"
                (click)="toggleDemoAlert('jobType', $event)"
              ></app-info-button>
            </div>
            <alert
              *ngIf="demoAlerts.jobType"
              color="main"
              [icon]="faCircleInfo"
              label="About job types"
              description="Lorem ipsum"
              styleClass="mt-3"
            ></alert>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType2"
              *ngIf="xmlFormJobType.invalid && (xmlFormJobType.touched || xmlFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type
            </label>
          </div>
        </div>
        <div class="col-12 md:col-6 pointer-events-none">
          <file-drop-input
            [id]="'jobInput_fileXML'"
            name="jobInput_fileXML"
            accept=".xml"
            acceptText="XML"
            label="XML file"
            (change)="fileUpload($event)"
            (drop)="drop($event)"
            [fileList]="uploadFiles"
          ></file-drop-input>
          <label
            htmlFor="jobInput_fileXML"
            id="username2-help"
            class="p-invalid"
            *ngIf="step1SelectedOption === 'option2' && newJobXML.invalid && uploadFiles.length === 0"
            >Please upload your XML file.
          </label>
          <small class="inputDescription">
            The XML file must be formatted with 3D point, edge, and face information. XML takeoffs from EagleView,
            TopView (v9.19 or later) and Hover are also compatible.
          </small>
        </div>
        <div class="col-12 md:col-6 ml-auto mt-3 flex flex-column align-items-end">
          <app-info-button pTooltip="About XML files" (click)="toggleDemoAlert('xmlFile', $event)"></app-info-button>
          <alert
            *ngIf="demoAlerts.xmlFile"
            color="main"
            [icon]="faCircleInfo"
            label="Uploading XML files"
            description="The map provides you with an accurate aerial view of your job location."
            styleClass="mt-3"
          ></alert>
        </div>
      </div>
    </section>

    <section *ngIf="step1SelectedOption === 'option3'">
      <h3 class="srOnly">Blueprints settings</h3>
      <div class="grid">
        <div class="col-12 md:col-6" [formGroup]="newJobBluePrint">
          <div class="inputCont">
            <label for="jobInput_jobName3" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName3"
              type="text"
              name="jobName"
              class="w-full"
              placeholder="Job name"
              formControlName="name"
              (blur)="nameValidation('Blueprints')"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName3"
              *ngIf="bluePrintsFormJobName.invalid && (bluePrintsFormJobName.touched || bluePrintsFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a job name
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType1" class="label">Job Type</label>
            <div class="flex column-gap-3">
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType1"
                optionValue="name"
              ></p-dropdown>
              <app-info-button
                pTooltip="About job types"
                (click)="toggleDemoAlert('jobType', $event)"
              ></app-info-button>
            </div>
            <alert
              *ngIf="demoAlerts.jobType"
              color="main"
              [icon]="faCircleInfo"
              label="About job types"
              description="Lorem ipsum"
              styleClass="mt-3"
            ></alert>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType1"
              *ngIf="bluePrintsFormJobType.invalid && (bluePrintsFormJobType.touched || bluePrintsFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type
            </label>
          </div>
          <div class="inputCont">
            <p class="label">Pricing model</p>
            <div class="inputCont_check">
              <p-radioButton
                inputId="jobInput_structure_single"
                name="pricingmodel"
                formControlName="pricingmodel"
                value="Single"
              ></p-radioButton>
              <label for="jobInput_structure_single" class="label_check">Single structure</label>
            </div>
            <div class="inputCont_check flex">
              <p-radioButton
                inputId="jobInput_structure_multiple"
                name="pricingmodel"
                formControlName="pricingmodel"
                value="Multi"
              ></p-radioButton>
              <label for="jobInput_structure_multiple" class="label_check mr-3">Multiple structures</label>
              <app-info-button
                pTooltip="About multiple structures"
                (click)="toggleDemoAlert('multipleStructures', $event)"
              ></app-info-button>
            </div>
            <alert
              *ngIf="demoAlerts.multipleStructures"
              color="main"
              [icon]="faCircleInfo"
              label="Working with multiple structures"
              description="Lorem ipsum"
              styleClass="mt-3"
            ></alert>
            <p
              id="username2-help"
              class="p-invalid"
              *ngIf="bluePrintsPricingmodel.invalid && (bluePrintsPricingmodel.touched || bluePrintsPricingmodel.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a pricing model
            </p>
          </div>
          <div class="inputCont">
            <label for="jobInput_coments" class="label">Comments</label>
            <textarea
              pInputTextarea
              id="jobInput_coments"
              class="w-full"
              placeholder="Comments"
              formControlName="comments"
            ></textarea>
          </div>
        </div>
        <div class="col-12 md:col-6 pointer-events-none">
          <file-drop-input
            [id]="'jobInput_fileXML'"
            name="jobInput_fileXML"
            [multiple]="true"
            accept=".pdf,image/png,image/jpeg"
            acceptText="PDF, JPG, PNG"
            label="Project files"
            (change)="fileUpload($event)"
            (drop)="drop($event)"
            [fileList]="uploadFiles"
          ></file-drop-input>
          <label
            htmlFor="jobInput_fileXML"
            id="username2-help"
            class="p-invalid"
            *ngIf="step1SelectedOption === 'option3' && newJobBluePrint.invalid && uploadFiles.length === 0"
            >Please upload your files.
          </label>
          <small class="inputDescription">
            Please include as many helpful documents as possible so your order can be processed in a timely manner.
            Valid documents may include:
            <ul>
              <li>Dimensioned Sketches (Slope or Ridge to Eave measurements included).</li>
              <li>Architectural Plans (Roof Plan, Dimensioned floor Plan and Elevations at a minimum).</li>
              <li>Aerial Photos (with a scale and all slopes included).</li>
              <li>Satellite Imagery (with a scale and all slopes included).</li>
            </ul>
          </small>
        </div>
        <div class="col-12 md:col-6 ml-auto flex flex-column">
          <app-info-button
            pTooltip="About blueprints"
            (click)="toggleDemoAlert('bluePrints', $event)"
            class="ml-auto"
          ></app-info-button>
          <alert
            *ngIf="demoAlerts.bluePrints"
            color="main"
            [icon]="faCircleInfo"
            label="How are blueprints processed?"
            description="Lorem ipsum"
            styleClass="mt-3"
          ></alert>
        </div>
      </div>
    </section>
    <alert
      *ngIf="isStep1Invalid()"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t set data source'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <alert
      *ngIf="showSearchResultMessageError === true"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t locate Address'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>

    <alert
      *ngIf="errorMessage"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Job name already exists'"
      [description]="'You already have another job with the same name. Please enter a new job name and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mt-4 mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="fullMobile" (click)="advanceToStep2()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </section>
  </step-card>

  <!-- Step 2 -->
  <step-card
    id="jobStep_2"
    number="2"
    label="Providers"
    [selected]="activeStep[1]"
    [textSelected]="'Choose data providers for this job:'"
    [completed]="completedStep[1]"
    [textCompleted]="getTextCompletedStep2()"
  >
    <alert
      color="main"
      [icon]="faCircleInfo"
      label="About providers"
      description="Lorem ipsum"
      styleClass="mb-4"
    ></alert>
    <section class="mb-4">
      <option-box
        [id]="'jobProvidersOption_ev'"
        [isRadio]="false"
        [value]="true"
        [disabled]="true"
        [checked]="step2SelectedOption.option1"
        image="assets/img/icons/source_ev.png"
        label="EagleView"
        [styleClass]="'borderBottom_0'"
        [(ngModel)]="step2SelectedOption.option1"
      >
        <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus officia porro earum nam.</span>
        <span *ngIf="step1SelectedOption === 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          This provider is required by your selected data source (Address).
        </span>
        <span *ngIf="step1SelectedOption !== 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          Your selected data source is not compatible with this provider.
        </span>
      </option-box>
      <app-service-item
        [label]="'EagleView'"
        [description]="
          'With only an address, you can obtain dimensions data from any location based on accurate aerial views provided by EagleView.'
        "
        [learnMoreLink]="'link'"
        [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
        [logo]="'assets/img/icons/source_ev.png'"
        [account]="'account'"
        [isServiceAvailable]="true"
        [disabled]="step1SelectedOption !== 'option1'"
        [isCompact]="true"
        [isLogoVisible]="false"
        [isDemo]="true"
        [infoTooltip]="'Account and Billing'"
        [infoAlertTitle]="'Partner system Account and Billing'"
        [infoAlertDescription]="'Lorem ipsum'"
        [isInfoAlertVisible]="demoAlerts.eagleViewAccountAndBilling"
        (infoButtonClick)="toggleDemoAlert('eagleViewAccountAndBilling', $event)"
      >
      </app-service-item>
    </section>

    <section>
      <option-box
        [id]="'jobProvidersOption_rw'"
        [isRadio]="false"
        [checked]="step2SelectedOption.option2"
        [disabled]="true"
        image="assets/img/icons/source_rw.png"
        label="Roofing WRX"
        [styleClass]="'borderBottom_0'"
        [value]="true"
        [(ngModel)]="step2SelectedOption.option2"
      >
        <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus officia porro earum nam.</span>
        <span *ngIf="step1SelectedOption !== 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          This provider is required by your selected data source.
        </span>
      </option-box>
      <app-service-item
        [label]="'Roofing WRX'"
        [learnMoreLink]="'link'"
        [logo]="'assets/img/icons/source_rw.png'"
        [account]="'account'"
        [isServiceAvailable]="isRoofingWRXActive === true"
        [isCompact]="true"
        [isLogoVisible]="false"
        [styleClass]="'borderBottom_0'"
        [isDemo]="true"
        [infoTooltip]="'Account and Billing'"
        [infoAlertTitle]="'Partner system Account and Billing'"
        [infoAlertDescription]="'Lorem ipsum'"
        [isInfoAlertVisible]="demoAlerts.roofingWRXAccountAndBilling"
        (infoButtonClick)="toggleDemoAlert('roofingWRXAccountAndBilling', $event)"
      >
      </app-service-item>
    </section>
    <alert
      *ngIf="step2HasError"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t set providers'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mt-0 mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="fullMobile" (click)="advanceToStep3()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
      <button pButton class="default outline fullMobile" type="button" (click)="changeStep(1, 0, true)">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Go back
      </button>
    </section>
  </step-card>

  <!-- Step 3 -->
  <step-card
    id="jobStep_3"
    number="3"
    label="Summary"
    [selected]="activeStep[2]"
    [textSelected]="'Please review your job and add any necessary information:'"
    [completed]="completedStep[2]"
    [textCompleted]="'Completed'"
  >
    <h2 class="srOnly">Summary</h2>

    <section>
      <div class="flex justify-content-between align-items-center">
        <div class="inputCont">
          <label for="jobInput_code" class="label">Promotional code (optional)</label>
          <input pInputText type="text" id="jobInput_code" placeholder="Enter code" />
        </div>
        <app-info-button
          pTooltip="Promotional codes"
          (click)="toggleDemoAlert('promotionalCode', $event)"
        ></app-info-button>
      </div>
      <alert
        *ngIf="demoAlerts.promotionalCode"
        color="main"
        [icon]="faCircleInfo"
        label="About promotional codes"
        description="Lorem ipsum"
        styleClass="mb-4"
      >
      </alert>
      <div class="inputCont">
        <label for="jobInput_notes" class="label">Notes</label>
        <textarea
          pInputTextarea
          id="jobInput_notes"
          class="w-full"
          placeholder="Enter additional details"
          [(ngModel)]="order.jobComments"
        ></textarea>
      </div>
      <alert
        *ngIf="representedUser && representedUser.name !== null"
        color="main"
        [icon]="faUser"
        label="{{ representedUser.name }} will be the owner of this job"
        description="You're about to create a job in behalf of another user ({{
          representedUser.name
        }}). This user will see this job in their account and have full control of it. Please make sure the user you entered is correct and that you're not sharing sensitive information."
        styleClass="mb-4"
      >
      </alert>
    </section>
    <alert
      *ngIf="step4HasError"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t create job'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="success fullMobile" (click)="openFinalStepModal()">
        <fa-icon [icon]="faCheck"></fa-icon>
        Create job
      </button>
      <button pButton type="button" class="outline default fullMobile" (click)="changeStep(2, 1, true)">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Go back
      </button>
    </section>
  </step-card>
</p-accordion>

<!-- Success -->
<section
  class="card card_border card_border_noMobile"
  *ngIf="completedStep[0] && completedStep[1] && completedStep[2] && completedStep[3]"
>
  <div class="jobTitleSummary">
    <div class="jobTitleSummary__imgCont">
      <img src="assets/img/icons/source_ev.png" alt="EagleView" class="jobTitleSummary__img" />
    </div>
    <h2 class="jobTitleSummary__titleCont">
      <span class="subtitle">Job No. 2345678</span>
      <span class="title3">Lorem ipsum dolor sit amet</span>
    </h2>
  </div>
  <div class="text-center mb-5">
    <div class="messageCircle main mb-3">
      <fa-icon [icon]="faCheck"></fa-icon>
    </div>
    <h3 class="title2 mb-3">Job created successfully!</h3>
    <p class="textWidthLimit">
      Your new job is being processed and is expected to be completed in a few hours. You can keep track its progress or
      create another job.
    </p>
  </div>
  <section class="footActionsCont spaced">
    <a routerLink="./jobs" pButton class="outline default fullMobile"> Go back to jobs list </a>
    <div class="footActionsCont">
      <button pButton class="outline main fullMobile" type="button">
        <fa-icon [icon]="faPlus"></fa-icon> Create another job
      </button>
      <button pButton class="main fullMobile" type="button">View job</button>
    </div>
  </section>
</section>

<app-dialog-first-step
  [isVisible]="currentStepModalOpen === 1"
  [jobDataSource]="step1SelectedOption"
></app-dialog-first-step>

<app-dialog-second-step
  [isVisible]="currentStepModalOpen === 2"
  [jobDataSource]="step1SelectedOption"
></app-dialog-second-step>

<app-dialog-third-step
  [isVisible]="currentStepModalOpen === 3"
  [jobDataSource]="step1SelectedOption"
  [onContinueClick]="createJob.bind(this)"
></app-dialog-third-step>
