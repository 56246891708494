import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { WindowRefService } from "../../shared/helpers/window-ref.service";
import { takeUntil } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { MenuItem, MessageService } from "primeng/api";
import { Menu } from "primeng/menu";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import {
  faBars,
  faBell,
  faCircleUser,
  faGear,
  faChevronDown,
  faChevronRight,
  faBuilding,
  faCog,
  faIdCard,
  faQuestionCircle,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { ManufacturerProfile } from "../data/models/AppInitializationData";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmService } from "../confirm/confirm.service";
import { AppInitializationData } from "../store/app.models";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  @Output() unlinkAll: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatExpansionPanel) accountMenu: MatExpansionPanel;
  @ViewChild(MatButtonToggleGroup) profileList: MatButtonToggleGroup;

  @Input() claims: any;
  ref: DynamicDialogRef | undefined;
  menuItems: MenuItem[];
  configs = this.appState.configs;
  busy: Subscription;

  profile$ = this.appState.profile$;
  profile: ManufacturerProfile = null;
  profiles: ManufacturerProfile[] = [];
  selectedProfile: number = this.appState.profileId;
  errorMessage: string;
  isAdmin: boolean = false;
  hasAtLeastOnePermission:boolean = false;
  private destroyed$ = new ReplaySubject<boolean>(1);

  faBars = faBars;
  faCircleUser = faCircleUser;
  faBell = faBell;
  faGear = faGear;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faBuilding = faBuilding;
  faCog = faCog;
  faIdCard = faIdCard;
  faQuestionCircle = faQuestionCircle;
  faSignOut = faSignOut;

  constructor(
    private appState: AppState,
    private windwService: WindowRefService,
    private profileService: ProfileService,
    private router: Router,
    public spinner: SpinnerService,
    private confirmService: ConfirmService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.appState.appDataOnce$.subscribe((data: AppInitializationData) => {
      this.filterProfiles(data.profiles);
      this.setMenuItems();
    });

    this.appState.activeProfiles$.pipe(takeUntil(this.destroyed$)).subscribe((profiles) => {
      this.filterProfiles(profiles);
      this.setMenuItems();
    });

    // get the initial list of profiles
    this.appState.appDataOnce$.subscribe((data: any) => {
      this.profiles =
        data.profiles.filter(
          (x: ManufacturerProfile) =>
            (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
            x.nonWRXRegistrationCompleted
        ) ?? [];
      if (data) {
        this.setMenuItems();
      }
    });

    // listen for current profile changes and select the correct item in list
    this.appState.profile$.pipe(takeUntil(this.destroyed$)).subscribe((profile) => {
      this.selectedProfile = profile.manufacturer.id;
      this.isAdmin = this.appState.isAdmin;
      this.profile = profile;
    });

    // subscribe to route changes
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      // Set list of manufacturers in menu when navigating from intial manufacturer selection.
      if (route instanceof NavigationEnd && route.url === "/jobs") {
        this.setMenuItems();
      }
    });

    this.appState.activeProfiles$.pipe(takeUntil(this.destroyed$)).subscribe((profiles) => {
      this.profiles =
        profiles.filter(
          (x: ManufacturerProfile) =>
            (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
            x.nonWRXRegistrationCompleted
        ) ?? [];

      this.setMenuItems();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  filterProfiles(profiles: ManufacturerProfile[]) {
    this.profiles =
      profiles.filter(
        (x: ManufacturerProfile) =>
          (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
          x.nonWRXRegistrationCompleted
      ) ?? [];
  }

  setMenuItems() {
    this.menuItems = [
      {
        label: "Unlink Accounts",
        escape: false,
        command: () => {
          this.logoutAllHandler();
        },
      },
    ];

    if (this.selectedProfile) {
      let selectedProfileName = this.profiles.find((x) => x.manufacturer?.id === this.selectedProfile)?.manufacturer
        .name;

      if (!this.profiles.length) {
        return;
      }

      if (this.profiles.length === 1) {
        this.menuItems.push({
          label: selectedProfileName,
          escape: false,
        });
      } else {
        let manufacturerProfiles = [];

        for (let profile of this.profiles) {
          if (profile.manufacturer.id === this.selectedProfile) {
            manufacturerProfiles.push({
              label: profile.manufacturer.name,
              icon: "pi pi-check",
              escape: false,
            });
          } else {
            manufacturerProfiles.push({
              label: profile.manufacturer.name,
              escape: false,
              command: () => {
                this.chooseProfile(profile);
              },
            });
          }
        }

        this.menuItems.push({
          label: "Manufacturers",
          escape: false,
          items: manufacturerProfiles,
        });
      }
    }
  }

  chooseProfile(profile: ManufacturerProfile) {
    this.selectedProfile = profile.manufacturer.id;
    this.setMenuItems();
    if (this.appState.profileId != profile.manufacturer.id) {
      this.busy = this.profileService.chooseProfile(profile.manufacturer.id).subscribe((_) => {
        this.closeMenu();
      });
    }
  }

  public closeMenu() {
    this.accountMenu?.close();
  }

  public openMenu(menu: Menu, event: any) {
    menu?.toggle(event);
  }

  accountHandler() {
    this.windwService.nativeWindow.open(this.appState.configs.AccountUrl);
  }

  logoutHandler() {
    this.confirmService.confirm(
      "Logout",
      "Are you sure you want to logout?",
      "pi pi-question-circle",
      () => {
        this.logout.emit(true);
      },
      () => {
        this.confirmService.close();
      }
    );
  }

  logoutAllHandler() {
    this.unlinkAll.emit(true);
  }
  redirectToAdminPanel(){
    this.appState.redirectToAdminPanel();
  }
  elevateYourProducts() {
    this.windwService.nativeWindow.open(this.appState.configs.ElevateYourProducts);
  }
}
