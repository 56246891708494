import { ServiceStatus } from "../data/models/AppInitializationData";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ThirdPartyAuth } from "../store/app.models";

interface ServiceDetail {
  learnMoreLink: string;
  title: string;
  description: string;
  disclaimer?: string;
  logo: string;
}

export type Service = ServiceDetail & ServiceStatus & ThirdPartyAuth;

export const SERVICE_DETAILS: Record<string, ServiceDetail> = {
  EagleView: {
    learnMoreLink: "/",
    title: AppConstants.ThirdPartyServiceName.EagleView,
    description:
      "With only an address, you can obtain dimensions data from any location based on accurate aerial views provided by EagleView.",
    disclaimer: "At this time, EagleView provides data for buildings in the United States and Canada only.",
    logo: "assets/img/icons/source_ev.png",
  },
  SmartBuild: {
    learnMoreLink: "/",
    title: AppConstants.ThirdPartyServiceName.SmartBuild,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    logo: "assets/img/icons/source_sb.png",
  },
  RoofingWRX: {
    learnMoreLink: "/",
    title: AppConstants.ThirdPartyServiceName.RoofingWRX,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    logo: "assets/img/icons/source_rw.png",
  },
};
