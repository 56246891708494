<div class="container_fluid">
  <div class="pageHeading">
    <h2 class="title2">Security and password</h2>
  </div>
  <section>
    <h2 class="title4 mb-3">Change password</h2>
    <div class="grid flex-column-reverse md:flex-row my-0">
      <div class="col-12 md:col-6 xl:col-5 py-0">
        <form action="#">
          <div class="inputCont">
            <label for="oldPassword" class="label">Old password</label>
            <div class="inputIconCont right">
              <input pPassword type="password" id="oldPassword" [feedback]="false" [showPassword]="showPassword" placeholder="Old password" class="w-full" aria-describedby="error_oldPassword">
              <button pButton type="button" class="default link square inputIconBtn" (click)="showPassword = !showPassword" title="Show/hide password">
                <fa-icon [icon]="showPassword ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p *ngIf="true" class="p-error" id="error_oldPassword"><fa-icon [icon]="faWarning"></fa-icon>Please enter your old password.</p>
          </div>
          <div class="inputCont">
            <label for="newPassword" class="label">New password</label>
            <div class="inputIconCont right">
              <input pPassword type="password" id="newPassword" [feedback]="false" [showPassword]="showPassword" placeholder="Old password" class="w-full" aria-describedby="error_newPassword">
              <button pButton type="button" class="default link square inputIconBtn" (click)="showPassword = !showPassword" title="Show/hide password">
                <fa-icon [icon]="showPassword ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p *ngIf="true" class="p-error" id="error_newPassword"><fa-icon [icon]="faWarning"></fa-icon>Please enter your new password.</p>
          </div>
          <div class="inputCont">
            <label for="newPasswordRepeat" class="label">Repeat new password</label>
            <div class="inputIconCont right">
              <input pPassword type="password" id="newPasswordRepeat" [feedback]="false" [showPassword]="showPassword" placeholder="Old password" class="w-full" aria-describedby="error_newPasswordRepeat">
              <button pButton type="button" class="default link square inputIconBtn" (click)="showPassword = !showPassword" title="Show/hide password">
                <fa-icon [icon]="showPassword ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p *ngIf="true" class="p-error" id="error_newPasswordRepeat"><fa-icon [icon]="faWarning"></fa-icon>Please enter your new password again.</p>
          </div>
          <button pButton class="fullMobile" type="button">
            Change password
          </button>
        </form>
      </div>
      <div class="col-12 md:col-6 xl:col-7">
        <div class="passwordInstructions mb-4">
          <p>Your password must have:</p>
          <ul class="passwordInstructionsList">
            <li>At least 8 characters.</li>
            <li>At least one lower case character.</li>
            <li>At least one upper case character.</li>
            <li>At least one number.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
