<div class="generalCont">
  <div class="mainCont">
    <div class="container container_steps">
      <div>
        <h1 class="pageTitle pageTitle_steps title2">Invite user</h1>
      </div>

      <p-accordion *ngIf="!completedStep[0] || !completedStep[1] || !completedStep[2]" [multiple]="false">
        <!-- Step 1 -->
        <step-card
          id="inviteStep_1"
          number="1"
          label="User type"
          [selected]="activeStep[0]"
          [completed]="completedStep[0]"
        >
          <h2 class="srOnly">User type</h2>

          <section class="optionsCont mb-4">
            <option-box
              [id]="'inviteTypeOption_1'"
              name="inviteType"
              value="option1"
              [selectedValue]="typeSelectedOption"
              (optionSelected)="selectOption('option1', 1)"
              [checked]="true"
              [icon]="faPerson"
              label="Nonaffiliated"
            >
              <span>Independent businesses can access the marketplace and trade with any company freely.</span>
            </option-box>
            <option-box
              [id]="'inviteTypeOption_2'"
              name="inviteType"
              value="option2"
              [selectedValue]="typeSelectedOption"
              (optionSelected)="selectOption('option2', 1)"
              [icon]="faBuilding"
              label="Employee"
            >
              <span
                >Permanent employees act in behalf of their company, manage their jobs and depend on it for
                billing.</span
              >
            </option-box>
            <option-box
              [id]="'inviteTypeOption_3'"
              name="inviteType"
              value="option3"
              [selectedValue]="typeSelectedOption"
              (optionSelected)="selectOption('option3', 1)"
              [icon]="faHandshakeSimple"
              label="Invited"
            >
              <span
                >The inviting company can absorb the cost of quotes made by invited users for a limited amount of jobs
                or time.</span
              >
            </option-box>
          </section>

          <section>
            <h3 class="srOnly">User company details</h3>
            <div class="inputCont">
              <div class="inputMultipleInlineCont">
                <p-dropdown
                  *ngIf="typeSelectedOption == 'option3'"
                  inputId="inviteInput_company"
                  [options]="manufacturers | async"
                  optionLabel="name"
                  [filter]="true"
                  class="w-full"
                  placeholder="Select company"
                  (onChange)="onCompanyChanged($event.value, 'inviter')"
                ></p-dropdown>
                <span *ngIf="typeSelectedOption == 'option3'" class="inputMultipleInlineCont__dividerText">to</span>
                <p-dropdown
                  inputId="inviteInput_company"
                  [options]="manufacturers | async"
                  optionLabel="name"
                  [filter]="true"
                  class="w-full"
                  placeholder="Select company"
                  (onChange)="onCompanyChanged($event.value, 'invitee')"
                ></p-dropdown>
              </div>
            </div>
            <section *ngIf="typeSelectedOption == 'option2'" class="">
              <h4 class="title4 mb-3">Company role</h4>
              <div class="optionsCont mb-4">
                <option-box
                  [id]="'inviteCompanyRoleOption_1'"
                  name="inviteCompanyRole"
                  value="Normal"
                  [selectedValue]="companyRoleSelectedOption"
                  (optionSelected)="selectOption('Normal', 2)"
                  [checked]="true"
                  [icon]="faScrewdriverWrench"
                  label="Normal"
                  [smallTitle]="true"
                >
                  <span>Contractors and regular employees. They can create and manage their own jobs only.</span>
                </option-box>
                <option-box
                  [id]="'inviteCompanyRoleOption_3'"
                  name="inviteCompanyRole"
                  value="Admin"
                  [selectedValue]="companyRoleSelectedOption"
                  (optionSelected)="selectOption('Admin', 2)"
                  [icon]="faCrown"
                  label="Administrator"
                  [smallTitle]="true"
                >
                  <span
                    >They have access to the company settings, where they can manage all jobs, billing details, invite
                    users, and more.</span
                  >
                </option-box>
              </div>
            </section>
          </section>

          <section class="footActionsCont spaced mt-5">
            <button
              pButton
              type="button"
              class="fullMobile"
              (click)="changeStep(0, 1, true)"
              [disabled]="
                invitation.companyId === null ||
                (typeSelectedOption === 'option3' && invitation.inviterCompanyId === null)
              "
            >
              Next
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
            <button routerLink="../user-management" pButton class="default outline fullMobile" type="button">
              <fa-icon [icon]="faArrowLeft"></fa-icon>
              Go back
            </button>
          </section>
        </step-card>

        <!-- Step 2 -->
        <step-card
          id="inviteStep_2"
          number="2"
          label="Account details"
          [selected]="activeStep[1]"
          [completed]="completedStep[1]"
        >
          <h2 class="srOnly">Account details</h2>

          <section>
            <div class="grid mb-0">
              <div class="col-12 md:col-6 lg:col-4 pb-0">
                <div class="inputCont">
                  <label for="inviteInput_email" class="label">Email</label>
                  <input
                    pInputText
                    type="email"
                    id="inviteInput_email"
                    class="w-full"
                    placeholder="Email"
                    [(ngModel)]="invitation.email"
                  />
                  <small class="inputDescription"
                    >The invitation and account activation link will be sent to this email. Please make sure this is a
                    valid email address.</small
                  >
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4 pb-0">
                <div class="inputCont">
                  <label for="inviteInput_phone" class="label">Phone Number</label>
                  <input
                    pInputText
                    id="inviteInput_phone"
                    class="w-full"
                    placeholder="Phone Number"
                    [(ngModel)]="invitation.contactPhoneNumber"
                  />
                </div>
              </div>
            </div>
            <div class="grid mb-0">
              <div class="col-12 md:col-6 lg:col-4 pb-0">
                <div class="inputCont">
                  <label for="inviteInput_firstName" class="label">First name</label>
                  <input
                    pInputText
                    type="text"
                    id="inviteInput_firstName"
                    class="w-full"
                    placeholder="First name"
                    [(ngModel)]="invitation.firstName"
                  />
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4 pb-0">
                <div class="inputCont">
                  <label for="inviteInput_lastName" class="label">Last name</label>
                  <input
                    pInputText
                    type="text"
                    id="inviteInput_lastName"
                    class="w-full"
                    placeholder="Last name"
                    [(ngModel)]="invitation.lastName"
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="footActionsCont spaced mt-5">
            <button
              pButton
              type="button"
              class="fullMobile"
              (click)="changeStep(1, 2, true)"
              [disabled]="
                invitation.email === null ||
                invitation.firstName === null ||
                invitation.lastName === null ||
                invitation.contactPhoneNumber === null
              "
            >
              Next
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
            <button pButton class="default outline fullMobile" type="button" (click)="changeStep(1, 0)">
              <fa-icon [icon]="faArrowLeft"></fa-icon>
              Go back
            </button>
          </section>
        </step-card>

        <!-- Step 3 -->
        <step-card
          id="inviteStep_3"
          number="3"
          label="Summary"
          [selected]="activeStep[2]"
          [completed]="completedStep[2]"
        >
          <h2 class="srOnly">Summary</h2>

          <section class="userSummary mt-3">
            <div class="userAvatar">
              <img src="assets/img/placeholder/userProfilePicPlaceholder.png" alt="" class="userAvatar__img" />
            </div>
            <div class="userSummaryContent">
              <h3 class="userSummary__text userSummary__name">
                <span class="srOnly">User name:</span>
                {{ invitation.firstName }} {{ invitation.lastName }}
              </h3>
              <p class="userSummary__text light">
                <span class="srOnly">Email:</span>
                {{ invitation.email }}
              </p>
              <p class="userSummary__text light">
                <span class="srOnly">Type:</span>
                <fa-icon [icon]="faScrewdriverWrench" class="userSummary__text__icon"></fa-icon>
                {{
                  typeSelectedOption === "option1"
                    ? "Contractor"
                    : typeSelectedOption === "option2"
                      ? "Employee"
                      : typeSelectedOption === "option3"
                        ? "User"
                        : "User"
                }}
                at {{ invitation.companyName }}
              </p>
            </div>
          </section>

          <p class="finalSummmaryMessage">
            An account activation link will be sent to
            <span class="fontW_semibold">{{ invitation.email }}</span
            >.
          </p>

          <section class="footActionsCont spaced mt-3">
            <button pButton type="button" class="success fullMobile" (click)="sendInvite()">
              <fa-icon [icon]="faCheck"></fa-icon>
              Invite user
            </button>
            <button pButton type="button" class="outline default fullMobile" (click)="changeStep(2, 1)">
              <fa-icon [icon]="faArrowLeft"></fa-icon>
              Go back
            </button>
          </section>
        </step-card>
      </p-accordion>

      <!-- Success -->
      <section
        class="card card_border card_border_noMobile"
        *ngIf="completedStep[0] && completedStep[1] && completedStep[2]"
      >
        <div class="userSummary userSummary_border">
          <div class="userAvatar userAvatar_small">
            <img src="assets/img/placeholder/userProfilePicPlaceholder.png" alt="" class="userAvatar__img" />
          </div>
          <h2 class="userSummaryContent">
            <span class="userSummary__text userSummary__name">
              <span class="srOnly">User name:</span>
              {{ invitation.firstName }} {{ invitation.lastName }}
            </span>
            <span class="userSummary__text light">
              <span class="srOnly">Email:</span>
              {{ invitation.email }}
            </span>
          </h2>
        </div>
        <div class="text-center mb-5">
          <div class="messageCircle main mb-3">
            <fa-icon [icon]="faCheck"></fa-icon>
          </div>
          <h3 class="title2 mb-3">User invited successfully!</h3>
          <p class="textWidthLimit">
            An invitation message with instructions and an activation link to get started with Roofing Passport has been
            sent to the new user.
          </p>
        </div>
        <section class="footActionsCont spaced">
          <button (click)="newInvite()" pButton class="outline main fullMobile" type="button">
            <fa-icon [icon]="faPlus"></fa-icon> Invite another user
          </button>
          <a routerLink="../user-management" pButton class="outline default fullMobile"> Go back to users list </a>
        </section>
      </section>
    </div>
  </div>
</div>
