<div class="flex flex-column lg:align-items-center">
  <div
    *ngIf="isInvited"
    class="flex align-items-center justify-content-center w-8rem h-8rem mt-3 p-5 border-1 border-circle border-outline"
  >
    <img src="assets/img/logo/rfLogo_icon.svg" alt="" class="block h-full" />
  </div>
  <div class="lg:text-center">
    <h2 class="title2 py-3">Welcome to Roofing Passport!</h2>
    <p class="text-sm max-w-32rem lg:text-center">
      The Sherwin-Williams Roofing Passport is built specifically for the metal residential & commercial roofing market.
      Our groundbreaking digital platform simplifies metal roof estimation and ordering for your projects.
    </p>
    <button *ngIf="isInvited" pButton class="link mt-2" (click)="toggleInfo()">
      {{ infoVisible ? "Show less" : "Learn more" }}
    </button>
  </div>
  <div class="grid my-0 pt-5" *ngIf="!isInvited || infoVisible">
    <div class="col-12 lg:col-4 flex lg:flex-column gap-4 lg:align-items-center mb-4 lg:mb-0">
      <img class="w-5rem h-5rem border-circle" src="assets/img/pictures/roofDetail_red.jpg" alt="" />
      <div class="lg:max-w-18rem">
        <h3 class="title4 lg:text-center">Estimation made easy with one seamless interface</h3>
        <p class="pt-3 text-sm lg:text-center">
          We're seamlessly integrated with multiple measurements systems, like EagleView, Roofing WRX and XML,
          SmartBuild, and more.
        </p>
      </div>
    </div>
    <div class="col-12 lg:col-4 flex lg:flex-column gap-4 lg:align-items-center mb-4 lg:mb-0">
      <img class="w-5rem h-5rem border-circle" src="assets/img/pictures/roofDetail_green.jpg" alt="" />
      <div class="lg:max-w-18rem">
        <h3 class="title4 lg:text-center">Maximize profitability with one powerful platform</h3>
        <p class="pt-3 text-sm lg:text-center">
          Bid any job and order materials with confidence. Increase your accuracy of estimates and reduce risks with
          ordering materials.
        </p>
      </div>
    </div>
    <div class="col-12 lg:col-4 flex lg:flex-column gap-4 lg:align-items-center">
      <img class="w-5rem h-5rem border-circle" src="assets/img/pictures/roofDetail_blue.jpg" alt="" />
      <div class="lg:max-w-18rem">
        <h3 class="title4 lg:text-center">Roofing support and estimate initiation</h3>
        <p class="pt-3 text-sm lg:text-center">
          We support all metal roofing project types, including new builds and reroofing. Estimates are quick and easy
          regardless of your data source.
        </p>
      </div>
    </div>
  </div>
  <div class="flex align-items-center gap-3 card card_border pb-3 mt-5 lg:mx-4" *ngIf="isInvited">
    <img
      src="assets/img/placeholder/companyProfilePicPlaceholder.png"
      alt=""
      class="h-4rem border-1 border-circle border-outline"
    />
    <p class="text-sm">
      You were invited by <span class="fontW_semibold">{{ profile.manufacturer.name }}</span> to join Roofing
      Passport.<br />Learn what this means for you <button pButton class="link">here</button>.
    </p>
  </div>

  <div class="footActionsCont spaced w-full pt-5">
    <button pButton class="main fullMobile" type="button" (click)="onBeginClick()">
      Begin
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
    <button pButton class="danger outline fullMobile" type="button" (click)="onRejectClick()">
      <fa-icon [icon]="faTimes"></fa-icon>
      Reject invitation
    </button>
  </div>
</div>
