import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faSave, faPen, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-account-settings-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent {
  faSave = faSave;
  faPen = faPen;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  user = this.appState.user;
  userForm: FormGroup;

  constructor(
    private appState: AppState,
    private fb: FormBuilder
  ) {
    this.userForm = this.fb.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      email: [this.user.email, [Validators.email, Validators.required]],
      phoneNumber: [this.user.phoneNumber, [Validators.required]],
    });
  }
}
