import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPen, faEllipsis, faEllipsisV, faPlus, faSitemap, faCircleNodes, faToggleOff, faTrash } from '@fortawesome/free-solid-svg-icons';
import { JobStatusEnum } from 'src/app/core/enums/job-status.enum';
import { AppState } from 'src/app/shared/services/app-state';
import { Company } from '../admin-users/edit-user/edit-user.models';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  constructor(private router: Router, private appState: AppState, private companyService: CompanyService) { }

  ngOnInit(): void {
    this.getAllCompanies();
  }
 
  companies: any[] = [];
  faEllipsisV = faEllipsisV;
  faEllipsis = faEllipsis;
  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;
  faSitemap = faSitemap;
  faCircleNodes = faCircleNodes;
  faToggleOff = faToggleOff;

  redirectTo(url: string, company: Company, index: string,) {
    this.appState.selectedCompany(company);
    this.router.navigate(['admin/' + url + '/' + index]);
  }
  getAllCompanies(){
    this.companyService.getAllCompanies().subscribe(data=>{
      this.companies= data;
    })
  }
}
