<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h3 class="title2">KPI Reporting</h3>
    </div>
    <div>
      <button pButton type="button" class="outline" pTooltip="Export as CSV" tooltipPosition="bottom" (click)="exportOrdersCSV()">
        <fa-icon [icon]="faDownload"></fa-icon>
        Export
      </button>
    </div>
  </div>
</div>

<app-order-statistics></app-order-statistics>

<div class="container_fluid">
  <section>
    <div>
      <div class="inputCont">
        <label class="label" for="fromDateFilter">From</label>
        <p-calendar
          id="fromDateFilter"
          [(ngModel)]="fromDateFilter"
          (ngModelChange)="onDateChange()"
          [readonlyInput]="true"
          inputId="fromDateFilter"
          dateFormat="mm-dd-yy"
          styleClass="block"
          inputStyleClass="w-10rem small"
          [appendTo]="'body'"
        ></p-calendar>
      </div>
      <div>
        <button
          *ngIf="areFiltersApplied()"
          type="button"
          pButton
          pRipple
          icon="pi pi-filter-slash"
          (click)="clearFilters()"
          pTooltip="Clear all filters"
          tooltipPosition="bottom"
        ></button>
      </div>
    </div>

    <p-table
      #ordersTable
      styleClass="p-datatable p-datatable-striped"
      [columns]="ordersTableColumns"
      [value]="orders"
      [rows]="10"
      [paginator]="true"
      [(selection)]="selectedOrder"
      [rowHover]="true"
      [loading]="isLoadingOrders"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of ordersTableColumns" pSortableColumn="{{ col.field }}" id="{{ col.field }}" scope="col">
            {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of ordersTableColumns" id="{{ col.field }}" scope="col">
            <input
              *ngIf="col.field !== 'createdOn'"
              pInputText
              type="text"
              (input)="ordersTable.filter($event.target.value, col.field, 'contains')"
              [value]="ordersTable.filters[col.field]?.value"
              [(ngModel)]="filters[col.field]"
              [placeholder]="col.header"
              class="w-full small"
            />
            <p-calendar
              *ngIf="col.field === 'createdOn'"
              [(ngModel)]="filters.createdOn"
              (ngModelChange)="ordersTable.filter(this.filters.createdOn, 'createdOn', 'dateEquals')"
              [placeHolder]="col.header"
              [readonlyInput]="true"
              dateFormat="mm-dd-yy"
              styleClass="block"
              inputStyleClass="w-full small"
              [placeholder]="col.header"
              [appendTo]="'body'"
            ></p-calendar>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order>
        <tr>
          <td headers="jobId">{{ order.jobNumber }}</td>
          <td headers="jobName">{{ order.jobName }}</td>
          <td headers="createdDate">{{ order.createdOn | date: "MM/dd/yyyy" : "+0000" }}</td>
          <td headers="createdBy">{{ order.createdBy }}</td>
          <td headers="jobStatus">{{ order.jobStatus }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <empty-state label="No orders found" description="There are no order for the selected timeframe."></empty-state>
      </ng-template>
    </p-table>
  </section>
  <p-messages styleClass="absolute bottom-0 right-0 z-5"></p-messages>
</div>
