import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, tap, finalize } from "rxjs/operators";
import { SmartBuildProject } from "src/app/core/data/models/SmartBuildProject";
import { JobStatusEnum, JobStatusIdEnum } from "src/app/core/enums/job-status.enum";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { SidebarService } from "src/app/shared/services/sidebar.service";
import { MessageService } from "primeng/api";
import { OrderService } from "src/app/shared/services/order.service";
import { JobCounts } from "src/app/core/data/models/JobCounts";
import { ApiEndpoints } from "src/app/shared/constants/api-endpoints";
import { AppState } from "src/app/shared/services/app-state";
import { AuthenticationService } from "src/app/shared/services/authentication.service";

import {
  faPlus,
  faCheckCircle,
  faRulerCombined,
  faHouseCircleCheck,
  faStore,
  faArchive,
  faEye,
  faEllipsisV,
  faDollar,
  faTrash,
  faReply,
  faDownload,
  faPaperPlane,
  faExclamation,
  faTimes,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "job-list",
  templateUrl: "./job-list.component.html",
  styleUrls: ["./job-list.component.scss"],
})
export class JobListComponent implements OnInit, OnDestroy {
  constructor(
    private smartbuildService: SmartBuildService,
    private spinner: SpinnerService,
    private sidebarService: SidebarService,
    private messageService: MessageService,
    private orderService: OrderService,
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  ngOnDestroy(): void {
    this.sidebarService.closeSidebar();
  }

  jobCounts?: JobCounts;
  jobs: any = [];
  files: any = [];
  jobStatuses = JobStatusEnum;
  selectedJob?: any;
  pageSize = 8;
  page = 1;

  display: boolean = false;
  contentPublished: boolean = true;
  isSidebarOpen: boolean = false;

  faPlus = faPlus;
  faCheckCircle = faCheckCircle;
  faRulerCombined = faRulerCombined;
  faHouseCircleCheck = faHouseCircleCheck;
  faStore = faStore;
  faArchive = faArchive;
  faEye = faEye;
  faEllipsisV = faEllipsisV;
  faDownload = faDownload;
  faDollar = faDollar;
  faReply = faReply;
  faTrash = faTrash;
  faPaperPlane = faPaperPlane;
  faExclamation = faExclamation;
  faTimes = faTimes;
  faInfo = faInfo;

  @ViewChild("sidebarContent") sidebarContent!: TemplateRef<any>;

  ngOnInit(): void {
    this.getAllJobs();
    this.appState.setAdminMenuItems();
  }

  getAllJobs() {
    this.spinner.show();
    this.smartbuildService
      .getProjects("false", this.page, this.pageSize)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.messageService.add({
            severity: "error",
            summary: err.error?.title || "Error",
            detail: err.error?.detail || "An error occurred while fetching jobs.",
          });
          return of([] as SmartBuildProject[]);
        }),
        tap((data: SmartBuildProject[]) => {
          this.jobs = data;

          this.jobCounts = {
            created: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Job Created"]).length,
            pendingTakeoff: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["TakeOff Report Generating"])
              .length,
            pendingDimensions: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Dimension Data Generating"])
              .length,
            completed: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Job Completed"]).length,
            informationRequired: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Information Required"])
              .length,
            canceled: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Job Canceled"]).length,
            failed: this.jobs.filter((x) => x.orderStatusId === JobStatusIdEnum["Job Failed"]).length,
          };
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe();
  }

  getJobStatusColor(jobStatusId: number): string {
    return this.jobStatuses[JobStatusIdEnum[jobStatusId]];
  }

  getJobStatus(jobStatusId: number): string {
    return JobStatusIdEnum[jobStatusId];
  }

  openSidebar(externalOrderId: string) {
    this.orderService
      .getRoofingWRXJobInfo(externalOrderId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.messageService.add({
            severity: "error",
            summary: err.error?.title || "Error",
            detail: err.error?.detail || "An error occurred while fetching job details",
          });
          return null;
        }),
        tap((job: any) => {
          this.files = job.files;
        })
      )
      .subscribe();

    this.sidebarService.openSidebar(this.sidebarContent);
  }

  publish() {
    this.contentPublished = !this.contentPublished;
  }

  onPageChange(page: number) {
    this.page = page;
    this.getAllJobs();
  }

  getTotalPages(jobsCount: number): number {
    return Math.round(jobsCount / 10);
  }

  downloadFile(fileName: string): void {
    if (!this.selectedJob) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "No job selected.",
      });
      return;
    }

    const params = {
      sequenceId: this.selectedJob.sequenceId,
      externalOrderId: this.selectedJob.externalOrderId,
      fileName: fileName,
    };

    const endpoint = ApiEndpoints.downloadJobFile(params);
    const url = `${this.appState.configs.ServicesBaseUrl}${endpoint}`;

    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });

    this.http.get(url, { headers, responseType: "blob" }).subscribe(
      (response: Blob) => {
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Download Error",
          detail: error.message || "Error downloading file",
        });
      }
    );
  }
}
