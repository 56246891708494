<div>
  <h2 class="title4 pb-3">Integrations</h2>
  <div>
    <p class="text-sm max-w-40rem">
      These are your currently available integrations. Integrations are third-party tools you can link to Roofing
      Passport in order to access additional features.
    </p>
    <app-third-party-integrations [services]="services"></app-third-party-integrations>
  </div>

  <section class="footActionsCont spaced mt-5">
    <div class="footActionsCont">
      <button pButton class="main fullMobile" type="button" (click)="onNextClick()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </div>
    <button pButton class="default outline fullMobile" type="button" (click)="onGoBackClick()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      Go back
    </button>
  </section>
</div>
