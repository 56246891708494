import { Component, Input, OnInit } from '@angular/core';
import { faEllipsisV, faBan, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit {

  faEllipsisV = faEllipsisV;
  faBan = faBan;
  faTrash = faTrash;

  constructor() { }

  ngOnInit(): void {
  }
}
