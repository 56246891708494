<p class="commentListIndicator">12 notes</p>
<ul class="commentList">
  <li class="comment">
    <div class="commentHead">
      <img src="assets/img/placeholder/userProfilePicPlaceholder.png" alt="" class="commentHead__img" />
      <p class="commentHead__text">
        <span class="commentHead__date">2 hours ago</span>
        <span class="commentHead__title"> <span class="commentHead__title__name">Jane Doe</span> • Company Inc. </span>
      </p>
    </div>
    <p class="commentBody">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </p>
  </li>
</ul>
