import { RouterModule, Routes } from "@angular/router";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { AdminUsersComponent } from "./admin-users/admin-users.component";
import { NgModule } from "@angular/core";
import { AdminComponent } from "./admin.component";
import { AdminReportingComponent } from "./admin-reporting/admin-reporting.component";
import { WorkQueueComponent } from "./work-queue/work-queue.component";
import { EditUserComponent } from "./admin-users/edit-user/edit-user.component";
import { EditCompanyComponent } from "./edit-company/edit-company.component";
import { InviteUserComponent } from "./invite-user/invite-user.component";
import { CompaniesListComponent } from "./companies-list/companies-list.component";

const AdminRoutes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "work-queue",
        component: WorkQueueComponent,
      },
      {
        path: "invite-user",
        title: "Invite user - Administration - Roofing Passport",
        component: InviteUserComponent,
      },
      {
        path: "manufacturer-settings",
        title: "Company - Administration - Roofing Passport",
        component: AdminSettingsComponent,
      },
      {
        path: "users",
        children: [
          {
            path: "",
            title: "Users - Administration - Roofing Passport",
            component: AdminUsersComponent,
          },
          {
            path: "edit-user/:id",
            title: "Edit user - Administration - Roofing Passport",
            component: EditUserComponent,
          },
        ],
      },
      {
        path: "reporting",
        title: "Reports - Administration - Roofing Passport",
        component: AdminReportingComponent,
      },
      {
        path: "companies",
        title: "Companies - Administration - Roofing Passport",
        component: CompaniesListComponent,
      },
      {
        path: "company",
        title: "Company - Administration - Roofing Passport",
        component: EditCompanyComponent,
      },
      {
        path: "company/:index",
        title: "Company - Administration - Roofing Passport",
        component: EditCompanyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(AdminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
