<section class="flex flex-column align-items-center">
  <div class="text-center mb-4">
    <div class="messageCircle main mt-4 mb-3">
      <fa-icon [icon]="faCheck"></fa-icon>
    </div>
    <h2 class="title2 mb-3">
      {{ isInvited ? "You're all set!" : "Email verified!" }}
    </h2>
    <p class="textWidthLimit">
      {{
        isInvited
          ? "Your account has been successfully activated. You can now access Roofing Passport."
          : "Your account has been successfully created."
      }}
    </p>
  </div>
  <alert
    *ngIf="!isInvited"
    color="main"
    [icon]="faInfoCircle"
    label="You can't access Roofing Passport yet"
    [description]="
      hasOwnCompany
        ? 'You\'ll gain access as soon as we verify your company information. We\'ll notify you via email once it\'s completed (this usually takes 24 to 48 hours).'
        : 'You\'ll gain access as soon as the company you applied to approves your request. We\'ll notify you via email once it\'s completed (this usually takes 24 to 48 hours).'
    "
    styleClass="max-w-40rem"
  >
  </alert>
</section>

<section *ngIf="isInvited" class="footActionsCont spaced">
  <button pButton class="main fullMobile" type="button" routerLink="../jobs">
    Get started
    <fa-icon [icon]="faArrowRight"></fa-icon>
  </button>
</section>
