import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { AppState } from './app-state';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndpoints } from '../constants/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
  ) { }

  getAllCompanies() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getAllCompanies}`;
    return this.httpClient.get<any[]>(url, { headers: headers });
  }

}
