import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { OidcSecurityService, LoginResponse, OpenIdConfiguration } from "angular-auth-oidc-client";
import { DOCUMENT } from "@angular/common";
import { OAuthErrorEventParams } from "src/app/core/data/models/OAuthErrorEventParams";

// Configuration IDs within OIDC auth module
export enum AuthContext {
  None = "",
  Azure = "AzureB2C",
  SmartBuild = "SmartBuild",
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private readonly doc: any
  ) {}

  private getRouteParams(): any {
    var hash = this.doc.location.hash.substr(1);
    return hash.split("&").reduce((resultData: any, item: string) => {
      const parts = item.split("=");
      resultData[parts.shift() as string] = parts.join("=");
      return resultData;
    }, {});
  }

  public getContexts(includeAzure: boolean): OpenIdConfiguration[] {
    var configurations: OpenIdConfiguration[] = this.oidcSecurityService.getConfigurations();

    if (!includeAzure) {
      configurations = configurations.filter((x) => x.configId !== AuthContext.Azure);
    }

    return configurations;
  }

  public checkAuth(context: string, url: string = null): Observable<LoginResponse> {
    const routeParams = this.getRouteParams();

    return new Observable((observer) => {
      this.oidcSecurityService.checkAuth(url, context).subscribe((response: LoginResponse) => {
        if (response.errorMessage) {
          observer.error(routeParams as OAuthErrorEventParams);
        }
        if (response.configId == AuthContext.Azure) {
          sessionStorage.setItem("access_token", response.accessToken);
          sessionStorage.setItem("id_token", response.idToken);
        }
        observer.next(response);
      });
    });
  }

  public isAuthenticated(context: AuthContext = AuthContext.Azure): boolean {
    return this.oidcSecurityService.isAuthenticated(context);
  }

  public accessToken(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getAccessToken(context);
  }

  public authResult(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getAuthenticationResult(context);
  }

  public claims(context: AuthContext = AuthContext.Azure): any {
    return this.oidcSecurityService.getPayloadFromIdToken(false, context);
  }

  public login(context: AuthContext = AuthContext.Azure, customParams: any = null) {
    if (customParams != null) {
      this.oidcSecurityService.authorize(context, {
        customParams: customParams,
      });
    } else {
      this.oidcSecurityService.authorize(context);
    }
  }

  public loginWithPopup(context: AuthContext = AuthContext.Azure): Observable<LoginResponse> {
    return this.oidcSecurityService.authorizeWithPopUp(null, null, context);
  }

  public logout(context: AuthContext = AuthContext.Azure) {
    this.oidcSecurityService.logoff(context);
  }

  public logoutAll(contexts: OpenIdConfiguration[]) {
    contexts.forEach((context) => {
      this.oidcSecurityService.logoff(context.configId);
    });
  }
}
