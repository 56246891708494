export interface CreateOrderRequest {
  jobName: string;
  jobType?: string;
  jobComments?: string;
  orderSequence: OrderSequence;
  address?: Address;
  hasChangedInLast4Years?: boolean;
  roofData: OrderRoofData;
  integrationPartnerSystemData?: IntegrationPartnerSystemData;
}

export interface OrderRoofData {
  orderModel: string;
  orderModelPartnerSystem: PartnerSystem;
}

export enum OrderSequence {
  RoofingWRXJob = 1,
  RoofingWRXXML = 5,
  EagleView = 2,
  LegacyRoofingWRXOnly = 3,
  EagleViewToRoofingWRX = 4,
}

export interface IntegrationPartnerSystemData {
  externalOrderId: string;
  partnerSystem: PartnerSystem;
}

export enum PartnerSystem {
  SmartBuild = 1,
  EagleView = 2,
  RoofingWRX = 3,
  GiddyUp = 4,
}

export type PricingModel = "Single" | "Multi";

export interface Order {
  branch_id: string;
  comments: string | null;
  name: string;
  isRevision: boolean;
  coverageWidth?: number;
  ridgeOffset?: number;
  hipOffset?: number;
  valleyOffset?: number;
  eaveOffset?: number;
  maxLength?: number;
  overlap?: number;
  clipValue?: number;
  fastenerValue?: number;
  panelType?: string;
  pricingmodel?: PricingModel;
}

export interface Address {
  street: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
}
