<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h2 class="title2">Job list</h2>
    </div>
    <div class="">
      <a routerLink="../jobs/new" pButton>
        <fa-icon [icon]="faPlus"></fa-icon>
        New job
      </a>
    </div>
  </div>
</div>

<section class="statusCardsCont" [ngClass]="{ small: isSidebarOpen === true }">
  <h3 class="srOnly">Stats</h3>
  <p class="statusCard started">
    <span class="statusCard__number">
      {{ jobCounts.created }}
      <fa-icon class="statusCard__icon mono" [icon]="faPlus"></fa-icon>
    </span>
    <span class="statusCard__label">Created</span>
  </p>
  <p class="statusCard pending-takeoff">
    <span class="statusCard__number">
      {{ jobCounts.pendingTakeoff }}
      <fa-icon class="statusCard__icon warning" [icon]="faPaperPlane"></fa-icon>
    </span>
    <span class="statusCard__label">Pending takeoff</span>
  </p>
  <p class="statusCard pending-dimensions">
    <span class="statusCard__number">
      {{ jobCounts.pendingDimensions }}
      <fa-icon class="statusCard__icon warning" [icon]="faRulerCombined"></fa-icon>
    </span>
    <span class="statusCard__label">Pending dimensions</span>
  </p>
  <p class="statusCard completed">
    <span class="statusCard__number">
      {{ jobCounts.completed }}
      <fa-icon class="statusCard__icon success" [icon]="faHouseCircleCheck"></fa-icon>
    </span>
    <span class="statusCard__label">Completed</span>
  </p>
  <p class="statusCard failed">
    <span class="statusCard__number">
      {{ jobCounts.failed }}
      <fa-icon class="statusCard__icon danger" [icon]="faExclamation"></fa-icon>
    </span>
    <span class="statusCard__label">Failed</span>
  </p>
  <p class="statusCard canceled">
    <span class="statusCard__number">
      {{ jobCounts.canceled }}
      <fa-icon class="statusCard__icon danger" [icon]="faTimes"></fa-icon>
    </span>
    <span class="statusCard__label">Canceled</span>
  </p>
  <p class="statusCard information-required">
    <span class="statusCard__number">
      {{ jobCounts.informationRequired }}
      <fa-icon class="statusCard__icon danger" [icon]="faInfo"></fa-icon>
    </span>
    <span class="statusCard__label">Information Required</span>
  </p>
</section>

<div class="container_fluid">
  <p-tabView>
    <p-tabPanel header="Active">
      <section *ngIf="jobs.length > 0">
        <h3 class="srOnly">Active jobs</h3>
        <p-table
          [value]="jobs"
          [paginator]="false"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first}-{last} of {totalRecords} elements"
          selectionMode="single"
          [(selection)]="job"
          [rows]="pageSize"
          margin="0px;"
          styleClass="p-datatable-striped responsiveTable"
        >
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" class="tableImgCell">Source</th>
              <th scope="col">Number</th>
              <th scope="col">Name</th>
              <th scope="col">Address</th>
              <th scope="col" *ngIf="isSidebarOpen === false">Last modified</th>
              <th scope="col" class="tableBadgeCell">Status</th>
              <th scope="col" class="tableButtonCell"><span class="srOnly">View</span></th>
              <th scope="col" class="tableButtonCell"><span class="srOnly">Actions</span></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job>
            <tr pSelectableRow (click)="openSidebar(job.externalOrderId); selectedJob = job">
              <td scope="row" class="tableImgCell">
                <!-- {{job.externalSource}} -->
                <img src="assets/img/icons/source_rw.png" alt="Roofing Works" class="tableImg" />
              </td>
              <td class="tableTitleCell tableLeftMarginCellMobile">
                {{ job.id }}
              </td>
              <td class="tableSubtitleCell tableLeftMarginCellMobile">
                {{ job.jobName }}
              </td>
              <td class="tableRegularCell">
                <span class="tableMobileLabel" aria-hidden="true">Address</span>
                {{
                  job.address
                    ? job?.address?.street +
                      "," +
                      job?.address?.city +
                      "," +
                      job?.address?.state +
                      "," +
                      job?.address?.zip
                    : null
                }}
              </td>
              <td class="tableRegularCell" *ngIf="isSidebarOpen === false">
                <span class="tableMobileLabel" aria-hidden="true">Last modified</span>
                {{ job.updatedOn | date: "shortDate" }}
              </td>
              <td class="tableBadgeCell">
                <status-badge
                  [text]="getJobStatus(job.statusId)"
                  [color]="getJobStatusColor(job.statusId)"
                  [hasBackground]="true"
                ></status-badge>
              </td>
              <td class="tableButtonCell">
                <button (click)="openSidebar(job.externalOrderId)" title="View job details" class="tableButton">
                  <fa-icon [icon]="faEye"></fa-icon>
                </button>
              </td>
              <td class="tableButtonCell">
                <button (click)="jobActionsMenu.toggle($event)" title="Job actions" class="tableButton">
                  <fa-icon [icon]="faEllipsisV"></fa-icon>
                </button>
                <p-overlayPanel #jobActionsMenu [appendTo]="'body'">
                  <ng-template pTemplate>
                    <ul class="dropdownMenu">
                      <li>
                        <button
                          (click)="openSidebar(job.externalOrderId); jobActionsMenu.toggle($event)"
                          class="dropdownButton"
                        >
                          <fa-icon [icon]="faDownload" class="dropdownButton__icon"></fa-icon>
                          <span>View job details</span>
                        </button>
                      </li>
                    </ul>
                  </ng-template>
                </p-overlayPanel>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <pagination
          [totalPages]="getTotalPages(jobs.length)"
          [currentPage]="page"
          [itemsPerPage]="10"
          [totalItems]="jobs.length"
          (pageChange)="onPageChange($event)"
          selectedJobId
        ></pagination>
      </section>
      <section *ngIf="jobs.length === 0">
        <empty-state label="No active jobs yet" description="Create a new job and it'll show up here.">
          <button pButton type="button" class="small">
            <fa-icon [icon]="faPlus"></fa-icon>
            New job
          </button>
        </empty-state>
      </section>
    </p-tabPanel>
  </p-tabView>
</div>

<ng-template #sidebarContent>
  <div class="detailSidebar">
    <div class="detailSidebar__header">
      <div class="detailSidebar__header__sourceImgCont">
        <img src="assets/img/icons/source_rw.png" alt="Roofing WRX" class="detailSidebar__header__sourceImg" />
      </div>
      <h4 class="detailSidebar__title">
        <span class="subtitle">Job No. {{ selectedJob.id }}</span>
        <span class="title3">Lorem ipsum dolor sit</span>
      </h4>
    </div>

    <div class="statusBar">
      <div class="statusBar__startCont">
        <p>
          <span class="srOnly">Status:</span>
          <status-badge [color]="'success'" [text]="'Completed'" [hasBackground]="false"></status-badge>
        </p>
        <p>Request date: {{ selectedJob.createdOn | date: "MM/dd/yyyy" }}</p>
      </div>
      <p>Last update: {{ selectedJob.updatedOn | date: "MM/dd/yyyy" }}</p>
    </div>

    <div class="detailSidebar__body">
      <div class="detailSidebar__tabsCont">
        <p-tabView>
          <p-tabPanel header="Files" *ngIf="selectedJob.sequenceId === 1 || selectedJob.sequenceId === 3">
            <section *ngIf="contentPublished">
              <h5 class="srOnly">Files</h5>
              <p-table
                [value]="files"
                [paginator]="false"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{totalRecords} files"
                margin="0px;"
              >
                <ng-template pTemplate="header">
                  <tr class="srOnly">
                    <th scope="col">Name</th>
                    <th scope="col">Download</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-file>
                  <tr>
                    <td>
                      {{ file.name }}
                    </td>
                    <td class="tableButtonCell">
                      <button title="Download file" class="tableButton" (click)="downloadFile(file.name)">
                        <fa-icon [icon]="faDownload"></fa-icon>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </section>
            <section *ngIf="!contentPublished">
              <empty-state
                label="No files yet"
                description="Generated takeoff report files will show up here."
              ></empty-state>
            </section>
          </p-tabPanel>
          <p-tabPanel header="Job notes">
            <section>
              <h5 class="srOnly">Job notes</h5>
              <div class="commentsCont">
                <comment-input></comment-input>
                <comment-list></comment-list>
              </div>
            </section>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-template>
