<div class="mt-3 flex justify-content-between flex-wrap gap-3 ">
    <p class="pagination-info p-2">Showing {{ (currentPage - 1) * itemsPerPage + 1 }}-{{ Math.min(currentPage *
        itemsPerPage, totalItems) }} of {{ totalItems }} elements
    </p>
    <div class="pagination">
        <button (click)="prevPage()" [disabled]="currentPage === 1" class="pagination-arrow ">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>

        <ul class="pagination-list ">
            <li *ngFor="let page of getPageRange()">
                <a href="#" class="pagination-link" [class.active]="page === currentPage" (click)="goToPage(page)">{{
                    page }}</a>
            </li>
            <li *ngIf="currentPage < totalPages - 2">
                <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li *ngIf="currentPage < totalPages - 1">
                <a href="#" class="pagination-link" (click)="goToPage(totalPages)">{{ totalPages }}</a>
            </li>
        </ul>

        <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="pagination-arrow">
            <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
    </div>
</div>