import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppState } from "./app-state";
import { AuthContext, AuthenticationService } from "./authentication.service";
import { ManufacturerInvitation } from "src/app/core/data/models/ManufacturerInvitation";
import { PartnerRegistration } from "src/app/core/data/models/PartnerRegistration";
import { ManufacturerProfile, ThirdPartyAuth } from "src/app/core/store/app.models";
import { ApiEndpoints } from "../constants/api-endpoints";
import { runInZone } from "../pipes/run-in-zone.pipe";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {}

  public chooseProfile(id: number): Observable<ManufacturerProfile> {
    this.appState.setProfile(id);
    return this.validateProfile();
  }

  public clearTokens(appContexts: AuthContext[]): Observable<ManufacturerProfile> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    return this.http
      .put<ManufacturerProfile>(`${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.clearTokens}`, appContexts, {
        headers,
      })
      .pipe(
        runInZone(this.ngZone),
        map((profile) => {
          return this.appState.updateProfile(profile);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      );
  }

  public validateProfile(): Observable<ManufacturerProfile> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.validateProfile}`;
    return this.http.get<ManufacturerProfile>(url, { headers }).pipe(
      runInZone(this.ngZone),
      map((profile) => {
        return this.appState.updateProfile(profile);
      }),
      catchError((err) => {
        console.log(err);
        return of(null);
      })
    );
  }

  public storeUserToken(context: AuthContext): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const authResult = this.authService.authResult(context);
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.storeUserToken(context)}`;
    return this.http.post<ThirdPartyAuth>(url, authResult, { headers }).pipe(
      runInZone(this.ngZone),
      map((auth) => {
        return this.appState.updateAuth(auth);
      })
    );
  }

  public requestPartnerSystemAccess(context: AuthContext): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const authResult = this.authService.authResult(context);
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.requestPartnerSystemAccess(context)}`;
    return this.http.post(url, authResult, { headers }).pipe(runInZone(this.ngZone));
  }

  public createInvitation(data: ManufacturerInvitation): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.createInvitation}`;
    return this.http.post(url, data, { headers }).pipe(runInZone(this.ngZone));
  }

  public resendRPInvitation(data: ManufacturerInvitation): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.resendRPInvitation}`;
    return this.http.post(url, data, { headers }).pipe(runInZone(this.ngZone));
  }

  public sendSmartBuildOutageMessage(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.sendSmartBuildOutageMessage}`;
    return this.http.post(url, [], { headers }).pipe(runInZone(this.ngZone));
  }

  public updateRoofingWRXEnabled(users: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateRoofingWRXEnabled}`;
    return this.http.post(url, users, { headers }).pipe(runInZone(this.ngZone));
  }

  // Change RoofingWRX enabled datapoint to true.
  public deactivateUser(users: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.deactivateUser}`;
    return this.http.post(url, users, { headers }).pipe(runInZone(this.ngZone));
  }

  public updateTrainingStatus(user: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateTrainingStatus}`;
    return this.http.post(url, user, { headers }).pipe(runInZone(this.ngZone));
  }

  public completeInvitation(companyId: number, accepted: boolean): Observable<{ success: boolean; error: string }> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.completeInvitation(companyId, accepted)}`;
    return this.http.post(url, {}, { headers, responseType: "text" as "json" }).pipe(
      runInZone(this.ngZone),
      map(() => ({ success: true, error: "" })),
      catchError((err: HttpErrorResponse) => of({ success: false, error: err.message }))
    );
  }

  public completeRegistration(
    data: PartnerRegistration,
    taskId: string
  ): Observable<{ success: boolean; error: string }> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.completeRegistration}`;
    return this.http.post(url, data, { headers }).pipe(
      runInZone(this.ngZone),
      map(() => ({ success: true, error: "" })),
      catchError((err: HttpErrorResponse) => of({ success: false, error: err.message }))
    );
  }

  public getAffiliatedCompanies(userId: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getAffiliatedCompanies(userId)}`;

    return this.http.get<any>(url, { headers }).pipe(
      runInZone(this.ngZone),
      map((companies) => {
        return companies;
      }),
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        return of(null);
      })
    );
  }
}
