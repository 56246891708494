import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faArrowRight, faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  @Input() isInvited: boolean;
  @Output() continueClicked = new EventEmitter<void>();

  hasOwnCompany: boolean = false;
  faArrowRight = faArrowRight;
  faCheck = faCheck;
  faInfoCircle = faInfoCircle;

  constructor() {}

  ngOnInit(): void {}
}
