import { Component } from "@angular/core";
import { faEye, faEyeSlash, faWarning } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "app-account-settings-security",
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.scss"],
})

export class SecurityComponent {
  showPassword: boolean = false;

  faEyeSlash = faEyeSlash;
  faEye = faEye;
  faWarning = faWarning;

  constructor() {}
}
