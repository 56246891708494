import { Component, Input, OnInit } from '@angular/core';
import { faPaperPlane, faWarning } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent implements OnInit {

  @Input() submit: string;
  @Input() image: string = "assets/img/placeholder/userProfilePicPlaceholder.png";
  @Input() placeholder: string = "Add a new note";

  faPaperPlane = faPaperPlane;
  faWarning = faWarning;

  constructor() { }

  ngOnInit(): void {
  }
}
