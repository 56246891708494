<section class="container_fluid">
  <form novalidate [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
    <div class="pageHeading">
      <h2 class="title2">Users</h2>
      <div class="flex gap-3">
        <button pButton (click)="navigateToInviteUser()" type="button">
          <fa-icon [icon]="faPlus"></fa-icon>
          Invite user
        </button>
        <button pButton class="outline square" type="button" (click)="exportUsers()" title="Export">
          <fa-icon [icon]="faDownload"></fa-icon>
        </button>
      </div>
    </div>

    <div class="flex align-items-center">
      <label class="text-xs mr-3" for="companyId">Company:</label>
      <p-dropdown
        class="w-full"
        styleClass="max-w-19rem w-full"
        (onChange)="onManufacturerSelectionChanged($event)"
        id="companyId"
        [options]="manufacturers | async"
        optionLabel="name"
        optionValue="id"
        formControlName="companyId"
        [filter]="(manufacturers | async)?.length > 1"
        [disabled]="(manufacturers | async)?.length <= 1"
      ></p-dropdown>
    </div>
    <p-table
      #dt
      styleClass="p-datatable-striped pt-5"
      [value]="adminManufacturerUsers"
      [rows]="10"
      [paginator]="true"
      dataKey="id"
      [scrollable]="true"
      scrollHeight="600px"
      [resizableColumns]="true"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first}-{last} of {totalRecords} elements"
    >
      <ng-template pTemplate="header">
        <tr class="bg-white">
          <th *ngFor="let column of usersTableColumns" scope="col">
            {{ column }}
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td class="font-semibold py-3">{{ user.fullName }}</td>
          <td>{{ user.companyName }}</td>
          <td>
            <badge [color]="getTrainingBadgeColor(user.trainingCompleted)">
              {{ user.trainingCompleted }}
            </badge>
          </td>
          <td>{{ user.eagleViewOrders }} {{ getOrderLabel(user.eagleViewOrders) }}</td>
          <td>{{ user.roofingWRXOrders }} {{ getOrderLabel(user.roofingWRXOrders) }}</td>
          <td>{{ user.xmlOrders }} {{ getOrderLabel(user.xmlOrders) }}</td>
          <td>{{ user.rpLastConnected | date: "MM/dd/yyyy" }}</td>
          <td>
            <status-badge text="N/A" color="danger" [hasBackgroundColor]="true"></status-badge>
          </td>
          <td class="tableButtonCell">
            <a class="tableButton" [routerLink]="['./edit-user', user.id]" pTooltip="Edit user" tooltipPosition="top">
              <fa-icon [icon]="faPen"></fa-icon>
            </a>
          </td>
          <td class="tableButtonCell">
            <button
              (click)="userActionsMenu.toggle($event)"
              class="tableButton"
              pTooltip="More actions"
              tooltipPosition="top"
            >
              <fa-icon [icon]="faEllipsisV" class="cursor-pointer"></fa-icon>
            </button>
            <p-overlayPanel #userActionsMenu [appendTo]="'body'">
              <ng-template pTemplate>
                <ul class="dropdownMenu">
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faEnvelope" class="dropdownButton__icon"></fa-icon>
                      <span>Resend activation email</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faAsterisk" class="dropdownButton__icon"></fa-icon>
                      <span>Reset password</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faToggleOn" class="dropdownButton__icon"></fa-icon>
                      <span>Activate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faToggleOff" class="dropdownButton__icon"></fa-icon>
                      <span>Deactivate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faTrash" class="dropdownButton__icon"></fa-icon>
                      <span>Delete</span>
                    </button>
                  </li>
                </ul>
              </ng-template>
            </p-overlayPanel>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-dialog
      appendTo="body"
      (onClose)="clearSelectedUser()"
      [(visible)]="viewUserDetailsDialog"
      [draggable]="false"
      [style]="{ width: '60vw' }"
      header="User Details"
      [modal]="true"
      styleClass="p-fluid"
    >
      <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="right">
          <div *ngIf="resendButton | async">
            <p-button
              (click)="resendRPInvitation()"
              label="Resend Invite"
              styleClass="p-button-raised p-button-text"
            ></p-button>
          </div>
          <div *ngIf="enableWRX | async">
            <p-button
              (click)="addRoofingWRX()"
              label="Enable WRX"
              styleClass="p-button-raised p-button-text p-button-secondary"
            ></p-button>
          </div>
          <div>
            <p-button
              (click)="deactivateUser()"
              label="Deactivate"
              styleClass="p-button-raised p-button-text p-button-danger"
            ></p-button>
          </div>
        </ng-template>
      </p-toolbar>
      <p-accordion>
        <p-accordionTab header="Update User Training Status">
          <div>
            <label>RP Onboard Training</label>
            <p-dropdown
              id="trainingStatusId"
              [options]="trainingSteps"
              formControlName="trainingStatusId"
              optionLabel="label"
              optionValue="value"
            ></p-dropdown>
          </div>
        </p-accordionTab>
      </p-accordion>
      <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="caption">
          <h4>
            {{ userForm?.controls.firstName.value }}
            {{ userForm?.controls.lastName.value }}
          </h4>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 11%; text-align: center">Invite Sent</th>
            <th style="width: 11%; text-align: center">Invite Accepted</th>
            <th style="width: 11%; text-align: center">Invite Date</th>
            <th style="width: 16%; text-align: center">Invitation Reedeemed Date</th>
            <th style="width: 16%; text-align: center">License Agreement Accepted</th>
            <th style="width: 12%; text-align: center">Completion Date</th>
            <th style="min-width: 10rem; text-align: center">Invited By</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-currentUser>
          <tr>
            <td style="width: 11%; text-align: center">
              <i *ngIf="currentUser.credentialsCreated" class="pi pi-check" style="color: green"></i>
              <i *ngIf="!currentUser.credentialsCreated" class="pi pi-times" style="color: red"></i>
            </td>
            <td style="width: 11%; text-align: center">
              <i
                *ngIf="currentUser.completionDate !== 'null' && currentUser.completionDate !== ''"
                class="pi pi-check"
                style="color: green"
              ></i>
              <i
                *ngIf="currentUser.completionDate === 'null' || currentUser.completionDate === ''"
                class="pi pi-times"
                style="color: red"
              ></i>
            </td>
            <td style="width: 11%; text-align: center">
              {{ currentUser.createdDate || "N/A" }}
            </td>
            <td style="width: 16%; text-align: center">
              {{ currentUser.invitationRedeemedDateTime || "N/A" }}
            </td>
            <td style="width: 16%; text-align: center">
              {{ currentUser.licenseAgreementAcceptedDate || "N/A" }}
            </td>
            <td style="width: 12%; text-align: center">
              {{ currentUser.completionDate || "N/A" }}
            </td>
            <td style="text-align: center">
              {{ currentUser.inviterName || "N/A" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th style="text-align: center">EV Credentials Created Date:</th>
            <th style="text-align: center">WRX Credentials Created Date</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-currentUser>
          <tr>
            <td style="text-align: center">
              {{ currentUser.eagleViewRegistrationCompleted || "N/A" }}
            </td>
            <td style="text-align: center">
              {{ currentUser.roofingWRXRegistrationCompleted || "N/A" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th style="text-align: center">EV Token Present:</th>
            <th style="text-align: center">WRX Token Present</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-currentUser>
          <tr>
            <td style="text-align: center">
              <i *ngIf="currentUser.evTokenPresent" class="pi pi-check" style="color: green"></i>
              <i *ngIf="!currentUser.evTokenPresent" class="pi pi-times" style="color: red"></i>
            </td>
            <td style="text-align: center">
              <i *ngIf="currentUser.wrxTokenPresent" class="pi pi-check" style="color: green"></i>
              <i *ngIf="!currentUser.wrxTokenPresent" class="pi pi-times" style="color: red"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <ng-template pTemplate="body">
        <div class="card">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="companyName"><b>Company Name:</b> {{ userForm?.controls.companyName?.value }}</label>
            </div>
            <div class="field col-12 md:col-6">
              <label for="companyAddress"><b>Company Address:</b> {{ userForm?.controls.companyAddress?.value }}</label>
            </div>
            <div class="field col-12 md:col-6">
              <label for="email"><b>Email:</b> {{ userForm?.controls.email?.value }}</label>
            </div>
            <div class="field col-12 md:col-6">
              <label for="phoneNumber"><b>Phone Number:</b> {{ userForm?.controls.phoneNumber?.value }}</label>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button
          pButton
          pRipple
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          (click)="saveUserTrainingStatus()"
        ></button>
      </ng-template>
    </p-dialog>
  </form>
</section>
