<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h2 class="title2">Companies</h2>
    </div>
    <div>
      <button pButton class="main float-right">
        <fa-icon [icon]="faPlus"></fa-icon>
        New company
      </button>
    </div>
  </div>

  <section>
    <p-table
      [value]="companies"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first}-{last} of {totalRecords} elements"
      [rows]="10"
      styleClass="p-datatable-striped"
      margin="0px;"
    >
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" class="">Name</th>
          <th scope="col" class="">Users</th>
          <th scope="col" class="">Parent company</th>
          <th scope="col" class="">Nodes</th>
          <th scope="col" class="tableBadgeCell">Status</th>
          <th scope="col" class="tableButtonCell"><span class="srOnly">Edit</span></th>
          <th scope="col" class="tableButtonCell"><span class="srOnly">Actions</span></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr>
          <td class="tableRegularCell">
            <span class="tableMobileLabel text-bold" aria-hidden="true">Name</span>
            {{company.name}}
          </td>
          <td class="tableRegularCell">
            <span class="tableMobileLabel" aria-hidden="true">Users</span>
            {{company.amountUsers}}
          </td>
          <td class="tableRegularCell">
            <span class="tableMobileLabel" aria-hidden="true">Parent company</span>
            {{company.parentCompany ? company.parentCompany.name : '-' }}
          </td>
          <td class="tableRegularCell">
            <span class="tableMobileLabel" aria-hidden="true">Nodes</span>
            {{company.amountNodes}}
          </td>
          <td class="tableBadgeCell">
            <status-badge [text]="company.isActive === true ? 'Active' : 'Inactive'" [color]="company.isActive === true ? 'success' : 'danger'" [hasBackground]="true">
            </status-badge>
          </td>
          <td class="tableButtonCell">
            <a (click)="redirectTo('company',company,0)" class="tableButton" title="Edit">
              <fa-icon [icon]="faPen"></fa-icon>
            </a>
          </td>
          <td class="tableButtonCell">
            <button (click)="jobActionsMenu.toggle($event)" title="Actions" class="tableButton">
              <fa-icon [icon]="faEllipsisV"></fa-icon>
            </button>
            <p-overlayPanel #jobActionsMenu [appendTo]="'body'">
              <ng-template pTemplate>
                <ul class="dropdownMenu">
                  <li (click)="redirectTo('company',company,1)">
                    <button class="dropdownButton">
                      <fa-icon [icon]="faSitemap" class="dropdownButton__icon"></fa-icon>
                      <span>View company structure</span>
                    </button>
                  </li>
                  <li (click)="redirectTo('company',company,2)">
                    <button class="dropdownButton">
                      <fa-icon [icon]="faCircleNodes" class="dropdownButton__icon"></fa-icon>
                      <span>View affiliated companies</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faToggleOff" class="dropdownButton__icon"></fa-icon>
                      <span>Deactivate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faTrash" class="dropdownButton__icon"></fa-icon>
                      <span>Delete</span>
                    </button>
                  </li>
                </ul>
              </ng-template>
            </p-overlayPanel>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
</div>
