import { RouterModule, Routes } from "@angular/router";
import { CompanySettingsComponent } from "./company-settings.component";
import { NgModule } from "@angular/core";
import { CompanyComponent } from "./company/company.component";

const CompanySettingsRoutes: Routes = [
  {
    path: "company-settings",
    component: CompanySettingsComponent,
    children: [
      {
        path: "company",
        component: CompanyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(CompanySettingsRoutes)],
  exports: [RouterModule],
})
export class CompanySettingsRoutingModule {}
