import { Component } from "@angular/core";
import { faFloppyDisk, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { OpenIdConfiguration } from "angular-auth-oidc-client";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { ThirdPartyAuth, UserRole } from "src/app/core/store/app.models";
import { Service, SERVICE_DETAILS } from "src/app/core/third-party-integrations/service-details";
import { AppState } from "src/app/shared/services/app-state";
import { AuthContext, AuthenticationService } from "src/app/shared/services/authentication.service";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-account-settings-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent {
  faQuestion = faQuestion;
  faFloppyDisk = faFloppyDisk;
  userRoles = [];
  roles = UserRole;
  serviceStatus = this.appState.serviceStatus;
  services: Service[] = [];

  constructor(
    private appState: AppState,
    private authService: AuthenticationService,
    private confirmService: ConfirmService,
    private profileService: ProfileService
  ) {
    this.appState.appData$.subscribe({
      next: (appData) => {
        this.userRoles = appData.user.roles;
      },
    });
  }

  mapServices(): void {
    this.services = this.serviceStatus.map((service) => {
      const details = SERVICE_DETAILS[service.service];
      const auth: ThirdPartyAuth = this.appState.authorizations.find(
        (p) => p.context.toString() == service.service.toString()
      );
      return {
        ...service,
        ...details,
        ...auth,
      };
    });
  }

  ngOnInit(): void {
    this.mapServices();
  }

  logoutAll() {
    var auths: AuthContext[] = [];
    var contexts: OpenIdConfiguration[] = this.authService.getContexts(false);

    contexts.forEach((context) => {
      auths.push(AuthContext[context.configId]);
    });

    this.confirmService.confirm(
      "Logout",
      `Are you sure you want to logout of all systems?`,
      "pi pi-question-circle",
      () => {
        this.profileService.clearTokens(auths).subscribe({
          next: () => {
            this.authService.logoutAll(contexts);
            this.mapServices();
          },
        });
      },
      () => {
        this.confirmService.close();
      }
    );
  }
}
