import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faFile,
  faGear,
  faInfoCircle,
  faLocationDot,
  faMap,
  faPlus,
  faSearch,
  faTriangleExclamation,
  faUpload,
  faUser,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { ReplaySubject, Subscription } from "rxjs";
import jobTypesList from "src/app/core/data/job-types";
import {
  Address,
  CreateOrderRequest,
  IntegrationPartnerSystemData,
  Order,
  OrderRoofData,
  OrderSequence,
  PartnerSystem,
} from "src/app/core/data/models/CreateOrderRequest";
import panelTypesList from "src/app/core/data/panel-types";
import states from "src/app/core/data/states";
import { PanelTypesEnum } from "src/app/core/enums/panel-types.enum";
import { ManufacturerProfile, RoofingWRXBranch } from "src/app/core/store/app.models";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { AppState } from "src/app/shared/services/app-state";
import { DialogService } from "src/app/shared/services/dialog.service";
import { OrderService } from "src/app/shared/services/order.service";
import { find as _find } from "lodash-es";
import { PictometryService } from "src/app/shared/services/pictometry.service";
import { takeUntil } from "rxjs/operators";
import { AppInitializerService } from "src/app/shared/services/app-initializer.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { timer } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "new-job",
  templateUrl: "./new-job.component.html",
  styleUrls: ["./new-job.component.scss"],
})
export class NewJobComponent implements OnInit {
  constructor(
    private scroller: ViewportScroller,
    private appState: AppState,
    private orderService: OrderService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private pictometryService: PictometryService,
    private appInitService: AppInitializerService,
    private messageService: MessageService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {}

  faCircleCheck = faCircleCheck;
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;
  faArrowRight = faArrowRight;
  faGear = faGear;
  faCheck = faCheck;
  faSearch = faSearch;
  faUpload = faUpload;
  faX = faX;
  faFile = faFile;
  faMap = faMap;
  faLocationDot = faLocationDot;
  faInfoCircle = faInfoCircle;
  faUser = faUser;
  faTriangleExclamation = faTriangleExclamation;
  panelTypes = panelTypesList;
  iconCompleted = null;

  activeStep: boolean[] = [true, false, false];
  completedStep: boolean[] = [false, false, false];
  step1SelectedOption: string = "option1";
  step2SelectedOption = {
    option1: false,
    option2: false,
  };
  pictometryUrl = "about:blank";
  isEagleViewActive: boolean = true;
  isRoofingWRXonly: boolean = false;
  isRoofingWRXActive: boolean = true;
  uploadFiles: any[] = [];
  jobTypes = jobTypesList;
  selectedJobType: any;
  profile: ManufacturerProfile;
  busy?: Subscription;
  roofingWRXJobsEnabled = false;
  representedUser: any = {
    id: null,
    name: null,
  };
  errorMessage?: string;
  branches: RoofingWRXBranch[] = [];
  selectedAddress: Address = {} as Address;
  selectedPartnerSytem: {};
  selectedOrderRoofData: OrderRoofData = {} as OrderRoofData;
  stateList = states;
  selectedIntegrationPartnerSystemData: IntegrationPartnerSystemData = {} as IntegrationPartnerSystemData;
  latitude: number;
  longitude: number;
  order: Order = {} as Order;
  showSearchResultMessage = false;
  showSearchResultMessageError = false;
  showAddressDiffMessage = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  step1HasError: boolean = false;
  step2HasError: boolean = false;
  jobNameExists = false;

  //Blueprints

  get bluePrintsFormJobName() {
    return this.newJobBluePrint.get("name");
  }
  get bluePrintsFormJobType() {
    return this.newJobBluePrint.get("jobType");
  }
  get bluePrintsPricingmodel() {
    return this.newJobBluePrint.get("pricingmodel");
  }
  get bluePrintsFormComments() {
    return this.newJobBluePrint.get("comments");
  }
  get bluePrintsBranchId() {
    return this.newJobBluePrint.get("branch_Id");
  }

  //XML
  get xmlFormJobType() {
    return this.newJobXML.get("jobType");
  }

  get xmlFormJobName() {
    return this.newJobXML.get("name");
  }

  // location
  get locationFormJobName() {
    return this.newJobLocation.get("name");
  }

  get locationFormComments() {
    return this.newJobLocation.get("comments");
  }

  get locationFormAddress() {
    return this.newJobLocation.get("address");
  }

  get locationFormCity() {
    return this.newJobLocation.get("city");
  }

  get locationFormState() {
    return this.newJobLocation.get("state");
  }

  get locationFormZip() {
    return this.newJobLocation.get("zip");
  }

  get locationFormJobType() {
    return this.newJobLocation.get("jobType");
  }
  get changesInLast4Years() {
    return this.newJobLocation.get("changesInLast4Years");
  }

  //RoofingWRX
  get panelType() {
    return this.newJobRoofingWRX.get("panelType");
  }

  get branchId() {
    this.newJobRoofingWRX.get("overlap");
    return this.newJobRoofingWRX.get("branch_Id");
  }

  get coverageWidth() {
    return this.newJobRoofingWRX.get("coverageWidth");
  }

  get ridgeOffset() {
    return this.newJobRoofingWRX.get("ridgeOffset");
  }

  get hipOffset() {
    return this.newJobRoofingWRX.get("hipOffset");
  }

  get valleyOffset() {
    return this.newJobRoofingWRX.get("valleyOffset");
  }

  get eaveOffset() {
    return this.newJobRoofingWRX.get("eaveOffset");
  }

  get maxLength() {
    return this.newJobRoofingWRX.get("maxLength");
  }

  get overlap() {
    return this.newJobRoofingWRX.get("overlap");
  }

  get fastenersCfs() {
    return this.newJobRoofingWRX.get("fastenersCfs");
  }

  get fastenersXf() {
    return this.newJobRoofingWRX.get("fastenersXf");
  }

  get fastenersCfc() {
    return this.newJobRoofingWRX.get("fastenersCfc");
  }

  get clipSpacingCfc() {
    return this.newJobRoofingWRX.get("clipSpacingCfc");
  }

  get roofingWRXOnly() {
    return this.newJobRoofingWRX.get("roofingWRXOnly");
  }

  orderRequest: CreateOrderRequest = { orderSequence: OrderSequence.EagleView } as CreateOrderRequest;

  newJobXML: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(29)]],
    jobType: ["", [Validators.required]],
    branch_Id: ["", Validators.required],
  });

  newJobBluePrint: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(29)]],
    jobType: ["", [Validators.required]],
    pricingmodel: ["Single", Validators.required],
    comments: [],
    branch_Id: ["", Validators.required],
  });

  newJobLocation: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(29)]],
    promoCode: [""],
    jobType: ["", [Validators.required]],
    address: ["", [Validators.required, Validators.maxLength(50)]],
    city: ["", [Validators.required, Validators.maxLength(50)]],
    state: ["", [Validators.required]],
    zip: [
      "",
      [
        Validators.required,
        Validators.pattern(new RegExp(`${AppConstants.USZipRegex}|${AppConstants.CAZipRegex}`, "i")),
      ],
    ],
    changesInLast4Years: [""],
  });

  newJobRoofingWRX: UntypedFormGroup = this.fb.group({
    branch_Id: [""],
    panelType: [""],
    user_Id: [""],
    coverageWidth: [0],
    ridgeOffset: [0],
    hipOffset: [0],
    valleyOffset: [0],
    eaveOffset: [0],
    maxLength: [200],
    overlap: [0],
    clipSpacingCfc: [24],
    fastenersCfc: [1],
    fastenersCfs: [12],
    fastenersXf: [85],
    roofingWRXOnly: [false],
  });

  ngOnInit(): void {
    this.appInitService.getIpaMapUrlSignature().subscribe((url: string) => {
      this.pictometryUrl = url + "&app_id=pictometry_ipa";
    });
    this.appState.userSelection.subscribe((data) => {
      this.representedUser = data;
    });

    this.appState.profile$.subscribe((profile: any) => {
      this.roofingWRXJobsEnabled = this.appState.roofingWRXJobsEnabled;
      this.branches = this.appState.roofingWRXBranches;
    });

    this.pictometryService
      .onImageDataSet()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleImageDataSet.bind(this));

    this.pictometryService
      .onPinPositionSet()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handlePinPositionSet.bind(this));

    this.pictometryService
      .onAddressSearchComplete()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleAddressSearchComplete.bind(this));
  }

  private handleImageDataSet(response): void {
    if (response) {
    } else {
      this.dialogService.eagleViewNoCoverage().subscribe((clickedPrimary) => {
        if (clickedPrimary) {
        }
      });
    }
  }

  private handlePinPositionSet(latlong: { lat: number; long: number }) {
    this.latitude = latlong.lat;
    this.longitude = latlong.long;
  }
  stepValidate() {
    if (this.step1SelectedOption === "option1") {
      if (this.newJobLocation.invalid) {
        this.step1HasError = true;
      } else {
        this.step1HasError = false;
      }
    }
    if (this.step1SelectedOption === "option2") {
      if (this.newJobXML.invalid) {
        this.step1HasError = true;
      } else {
        this.step1HasError = false;
      }
    }
    if (this.step1SelectedOption === "option3") {
      if (this.newJobBluePrint.invalid) {
        this.step1HasError = true;
      } else {
        this.step1HasError = false;
      }
    }
  }

  changeStep(currentStep: number, targetStep: number, isGoBack: boolean, complete?: boolean) {
    if (currentStep === 0 && isGoBack === false) {
      this.stepValidate();
      if (this.step1HasError === true) return;
    }
    this.activeStep[currentStep] = false;
    this.activeStep[targetStep] = true;
    this.scroller.scrollToAnchor("jobStep_" + targetStep);

    if (complete) this.completedStep[currentStep] = true;
  }

  selectJobDataSource(option: number) {
    if (option === 1) {
      this.step1SelectedOption = "option1";
      this.orderRequest.orderSequence = OrderSequence.EagleView;
    }
    if (option === 2) {
      this.step1SelectedOption = "option2";
      this.orderRequest.orderSequence = OrderSequence.RoofingWRXXML;
    }
    if (option === 3) {
      this.step1SelectedOption = "option3";
      this.orderRequest.orderSequence = OrderSequence.RoofingWRXJob;
    }
    this.uploadFiles = [];
  }

  toggleRoofingWRXonly(option) {
    this.isRoofingWRXonly = option;
    this.step2SelectedOption.option2 = !this.step2SelectedOption.option2;
  }

  drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === "application/xml" || files[i].type === "text/xml") {
          this.uploadFiles.push(files[i]);
          console.log("Its an XML!!", files[i]);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "File Error",
            detail: "it is not an XML",
          });
        }
      }
    }
  }

  fileUpload(event: any) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      const allowedTypes =
        this.step1SelectedOption === "option2" ? [".xml"] : [".pdf", ".png", ".jpg", ".jpeg", ".xml"];

      const newFiles = Array.from(files).filter((file) => {
        const fileExtension = "." + file.name.split(".").pop().toLowerCase();
        return allowedTypes.includes(fileExtension);
      });

      if (newFiles.length > 0) {
        this.uploadFiles = [...this.uploadFiles, ...newFiles];
      }

      if (newFiles.length !== files.length) {
        this.messageService.add({
          severity: "error",
          summary: "File Error",
          detail: "Some files were not added because they are not of the correct type.",
        });
      }
    }
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
    if (this.uploadFiles.length === 0) {
      this.resetFileInput();
    }
  }

  resetFileInput() {
    this.uploadFiles = [];
  }

  onChangeRoofingWRXOnly() {
    const newValue = !this.roofingWRXOnly.value;
    this.newJobRoofingWRX.get("roofingWRXOnly").setValue(newValue);

    const controls = [
      { name: "panelType", validators: [Validators.required] },
      { name: "coverageWidth", validators: [Validators.required] },
      { name: "ridgeOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "hipOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "valleyOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "eaveOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "maxLength", validators: [Validators.required, Validators.min(1), Validators.max(200)] },
      { name: "overlap", validators: [Validators.required, Validators.min(0), Validators.max(24)] },
      { name: "clipSpacingCfc", validators: [Validators.required, Validators.min(1), Validators.max(120)] },
      { name: "fastenersCfc", validators: [Validators.required, Validators.min(1), Validators.max(5)] },
      { name: "fastenersCfs", validators: [Validators.required, Validators.min(1), Validators.max(120)] },
      { name: "fastenersXf", validators: [Validators.required, Validators.min(1), Validators.max(300)] },
    ];

    controls.forEach((control) => {
      if (newValue) {
        this.newJobRoofingWRX.controls[control.name].setValidators(control.validators);
      } else {
        this.newJobRoofingWRX.controls[control.name].clearValidators();
      }
      this.newJobRoofingWRX.controls[control.name].updateValueAndValidity();
    });
  }

  nameValidation(jobFormName: string) {
    var name;
    if (jobFormName === "Blueprints") {
      name = this.newJobBluePrint.get("name");
    }
    if (jobFormName === "Location") {
      name = this.newJobLocation.get("name");
    }
    if (jobFormName === "XML") {
      name = this.newJobXML.get("name");
    }

    this.orderService.NameValidation(name.value).subscribe(
      (_data) => {
        this.jobNameExists = false;
      },
      (_err) => {
        if (_err.status === AppConstants.HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
          this.jobNameExists = true;
        }
      }
    );
  }

  createJobRoofingWRX() {
    const maxLength = this.newJobRoofingWRX.get("maxLength").value;
    if (!maxLength || maxLength === 0) {
      this.newJobRoofingWRX.get("maxLength").setValue(200);
    }

    if (this.branches?.length > 0 && this.newJobRoofingWRX.valid && this.roofingWRXJobsEnabled) {
      let clipValue = null;
      let fastenerValue = null;

      const WRXOnly = this.isRoofingWRXonly;

      if (WRXOnly) {
        clipValue = this.panelType.getRawValue() === PanelTypesEnum.CFC ? this.clipSpacingCfc.getRawValue() : 0;
        fastenerValue =
          this.panelType.getRawValue() === PanelTypesEnum.CFC
            ? this.fastenersCfc.getRawValue()
            : this.panelType.getRawValue() === PanelTypesEnum.CFS
              ? this.fastenersCfs.getRawValue()
              : this.fastenersXf.getRawValue();
      }

      const order: Order = {
        name: this.bluePrintsFormJobName.getRawValue(),
        branch_id: this.bluePrintsBranchId.getRawValue(),
        comments: this.bluePrintsFormComments.getRawValue(),
        isRevision: false,
        coverageWidth: WRXOnly ? this.coverageWidth.getRawValue() : null,
        ridgeOffset: WRXOnly ? this.ridgeOffset.getRawValue() : null,
        hipOffset: WRXOnly ? this.hipOffset.getRawValue() : null,
        valleyOffset: WRXOnly ? this.valleyOffset.getRawValue() : null,
        eaveOffset: WRXOnly ? this.eaveOffset.getRawValue() : null,
        maxLength: WRXOnly ? this.maxLength.getRawValue() : null,
        overlap: WRXOnly ? this.overlap.getRawValue() : null,
        panelType: WRXOnly ? this.panelType.getRawValue() : PanelTypesEnum.Normal,
        clipValue: clipValue,
        fastenerValue: fastenerValue,
        pricingmodel: this.bluePrintsPricingmodel.getRawValue(),
      };

      this.orderRequest = {
        jobName: this.bluePrintsFormJobName.getRawValue(),
        jobType: this.bluePrintsFormJobType.getRawValue(),
        jobComments: this.bluePrintsFormComments.getRawValue(),
        orderSequence: OrderSequence.RoofingWRXJob,
        roofData: {
          orderModel: JSON.stringify(order),
          orderModelPartnerSystem: PartnerSystem.RoofingWRX,
        },
      };

      this.orderService.processNewOrder(this.orderRequest, this.uploadFiles).subscribe(
        (response: any) => {
          this.router.navigate([
            "/jobs/created-successfully",
            { dataSource: "RoofingWRX", jobName: this.bluePrintsFormJobName.getRawValue(), jobNumber: response.id },
          ]);
        },
        (responseError) => {
          if (typeof responseError.error === "object") {
            this.errorMessage =
              responseError.error.error_description ?? this.appState.configs.NewOrderCommunicationErrorMessage;
          } else {
            this.errorMessage = responseError.error;
          }

          this.messageService.add({
            severity: "error",
            summary: "Process New Order Error",
            detail: this.errorMessage,
          });
        }
      );
    }
  }

  createJobEagleView() {
    this.validateAllFormFields(this.newJobLocation);

    if (!this.newJobLocation.valid) {
      return;
    }

    const orderModel = {
      ...this.newJobLocation.value,
      projectName: this.locationFormJobName.getRawValue(),
      latitude: this.latitude,
      longitude: this.longitude,
      changesInLast4Years: this.changesInLast4Years.value ? this.changesInLast4Years.value : false,
    };

    const payload: CreateOrderRequest = {
      jobName: this.locationFormJobName.getRawValue(),
      jobType: this.locationFormJobType.getRawValue(),
      jobComments: this.locationFormComments ? this.locationFormComments.getRawValue() : "",

      address: {
        street: this.locationFormAddress.getRawValue(),
        city: this.locationFormCity.getRawValue(),
        state: this.locationFormState.getRawValue(),
        zip: this.locationFormZip.getRawValue(),
        countryCode: "USA",
      },
      orderSequence: OrderSequence.EagleView,
      hasChangedInLast4Years: this.changesInLast4Years.value ? this.changesInLast4Years.value : false,
      roofData: {
        orderModel: JSON.stringify(orderModel),
        orderModelPartnerSystem: PartnerSystem.EagleView,
      },
    };

    this.orderService.processNewOrder(payload).subscribe(
      (response: any) => {
        this.router.navigate([
          "/jobs/created-successfully",
          { dataSource: "EagleView", jobName: this.locationFormJobName.getRawValue(), jobNumber: response.id },
        ]);
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Process New Order Error",
          detail: err.errro?.detail,
        });
        this.errorMessage = err;
        //this.submitButtonDisabled = false;
        //this.handleNewOrderErrorResponse(error);
      }
    );
  }

  private handleAddressSearchComplete(response: any): void {
    this.latitude = null;
    this.longitude = null;
    const selectedFeature =
      response.featureCount > 1 ? _find(response.features, { success: true }) : response.features[0];

    this.showSearchResultMessage = true;
    const addressFields = selectedFeature.fields;
    if (_find(addressFields, { name: "Street" })?.value !== this.newJobLocation.controls["address"].value) {
      this.newJobLocation.controls["address"].patchValue(_find(addressFields, { name: "Street" }).value);
      this.showAddressDiffMessage = true;
    }
    if (_find(addressFields, { name: "State" })?.value !== this.newJobLocation.controls["state"].value) {
      this.newJobLocation.controls["state"].patchValue(_find(addressFields, { name: "State" }).value);
      this.showAddressDiffMessage = true;
    }
    if (_find(addressFields, { name: "City" })?.value !== this.newJobLocation.controls["city"].value) {
      this.newJobLocation.controls["city"].patchValue(_find(addressFields, { name: "City" }).value);
      this.showAddressDiffMessage = true;
    }
    let postalCode: string = _find(addressFields, { key: "postalCode" })?.value;
    const caZipMatch = new RegExp(AppConstants.CAZipRegex, "i").exec(postalCode);
    if (caZipMatch == null) {
      // for US postal addresses, only use the first 5 digits
      postalCode = postalCode.split("-")[0];
    }
    if (postalCode !== this.newJobLocation.controls["zip"].value) {
      this.newJobLocation.controls["zip"].patchValue(postalCode);
      this.showAddressDiffMessage = true;
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  findAddress() {
    this.spinnerService.show();
    this.latitude = null;
    this.longitude = null;
    const addressString = `${this.locationFormAddress.getRawValue()},${this.locationFormCity.getRawValue()},${this.locationFormState.getRawValue()},${this.locationFormZip.getRawValue()}`;

    const FOUR_SECONDS = 4000;
    const searchTimer = timer(FOUR_SECONDS);

    this.pictometryService.setAddress(addressString);

    searchTimer.subscribe(() => {
      this.spinnerService.hide();
    });
  }

  createXMLJob() {
    const order: Order = {
      branch_id: this.newJobXML.get("branch_Id").value,
      comments: null,
      name: this.xmlFormJobName.getRawValue(),
      isRevision: false,
    };

    const request: CreateOrderRequest = {
      jobName: this.xmlFormJobName.getRawValue(),
      jobType: this.xmlFormJobType.getRawValue(),
      orderSequence: OrderSequence.RoofingWRXXML,
      roofData: {
        orderModel: JSON.stringify(order),
        orderModelPartnerSystem: PartnerSystem.RoofingWRX,
      },
    };

    if (this.newJobXML.valid) {
      this.errorMessage = null;
      this.orderService.processNewOrder(request, this.uploadFiles).subscribe(
        (response: any) => {
          this.router.navigate([
            "/jobs/created-successfully",
            { dataSource: "RoofingWRX", jobName: this.xmlFormJobName.getRawValue(), jobNumber: response.id },
          ]);
        },
        (responseError) => {
          if (typeof responseError.error === "object") {
            this.errorMessage = responseError.error.error_description ?? AppConstants.XmlGenericError;
          } else {
            this.errorMessage = responseError.error;
          }

          this.messageService.add({
            severity: "error",
            summary: "Process New Order Error",
            detail: this.errorMessage,
          });
        }
      );
    }
  }

  createJob() {
    this.errorMessage = null;
    if (this.orderRequest.orderSequence === OrderSequence.RoofingWRXJob) {
      this.createJobRoofingWRX();
    }
    if (
      this.orderRequest.orderSequence === OrderSequence.EagleView ||
      this.orderRequest.orderSequence === OrderSequence.EagleViewToRoofingWRX
    ) {
      this.createJobEagleView();
    }
    if (this.orderRequest.orderSequence === OrderSequence.RoofingWRXXML) {
      this.createXMLJob();
    }
  }

  getTextCompletedStep1(): string {
    switch (this.step1SelectedOption) {
      case "option1":
        this.iconCompleted = this.faLocationDot;
        return `Address: ${this.newJobLocation.value.address}, ${this.newJobLocation.value.city}, ${this.newJobLocation.value.state}`;
      case "option2":
        this.iconCompleted = this.faFile;
        return this.uploadFiles.length > 0 ? `XML File: ${this.uploadFiles[0].name}` : null;
      case "option3":
        this.iconCompleted = this.faMap;
        return `Blueprints: ${this.uploadFiles.length} file(s)`;
      default:
        return "SOURCE";
    }
  }

  getTextCompletedStep2(): string {
    let response = "";

    if (this.step2SelectedOption.option1 === true) {
      response += "Eagle view ";
    }
    if (this.step2SelectedOption.option2 === true) {
      response += "Roofing WRX";
    }
    return response;
  }

  isStep1Invalid(): boolean {
    const { step1SelectedOption, newJobLocation, newJobXML, newJobBluePrint, step1HasError } = this;
    return (
      step1HasError &&
      ((step1SelectedOption === "option1" && newJobLocation.invalid) ||
        (step1SelectedOption === "option2" && newJobXML.invalid) ||
        (step1SelectedOption === "option3" && newJobBluePrint.invalid))
    );
  }
}
