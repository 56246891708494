<div>
  <h3 class="srOnly">Profile</h3>

  <form [formGroup]="manufacturerForm">
    <input type="hidden" id="manufacturerId" formControlName="manufacturerId" class="srOnly" />

    <div class="grid my-0 flex-column-reverse xl:flex-row">
      <div class="col-12 xl:col-9 py-0">
        <div class="mb-2">
          <div class="inputCont">
            <label for="companyProfile_name" class="label">Company name *</label>
            <input
              type="text"
              id="companyProfile_name"
              class="w-full"
              pInputText
              placeholder="Company name"
              formControlName="name"
            />
          </div>
          <div class="inputCont">
            <label for="companyProfile_description" class="label">Description</label>
            <textarea
              id="companyProfile_description"
              formControlName="description"
              class="w-full"
              placeholder="Description"
              pInputTextarea
            ></textarea>
          </div>
        </div>

        <section class="mb-2">
          <h4 class="title4 mb-3">Legal address</h4>
          <div class="grid my-0">
            <div class="col-12 xl:col-8 py-0">
              <div class="inputCont">
                <label for="companyProfile_street" class="label">Street *</label>
                <input
                  type="text"
                  id="companyProfile_street"
                  class="w-full"
                  pInputText
                  placeholder="Street"
                  formControlName="street"
                />
              </div>
            </div>
            <div class="col-12 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_city" class="label">City *</label>
                <input
                  type="text"
                  id="companyProfile_city"
                  class="w-full"
                  pInputText
                  placeholder="City"
                  formControlName="city"
                />
              </div>
            </div>
            <div class="col-12 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_state" class="label">State/Province *</label>
                <p-dropdown
                  inputId="companyProfile_state"
                  [options]="states"
                  optionLabel="name"
                  [filter]="true"
                  optionValue="abbreviation"
                  class="w-full"
                  placeholder="Select state/province"
                  formControlName="state"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-6 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_postalCode" class="label">Postal code *</label>
                <input
                  type="text"
                  id="companyProfile_postalCode"
                  class="w-full"
                  pInputText
                  placeholder="Postal code"
                  formControlName="postalCode"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="mb-2">
          <h4 class="title4 mb-3">Contact info</h4>
          <div class="grid my-0">
            <div class="col-12 xl:col-8 py-0">
              <div class="inputCont">
                <label for="companyProfile_website" class="label">Website</label>
                <input
                  type="text"
                  id="companyProfile_website"
                  class="w-full"
                  pInputText
                  placeholder="Website"
                  formControlName="website"
                />
              </div>
            </div>
          </div>
          <div class="grid my-0">
            <div class="col-12 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactName" class="label">Contact name</label>
                <input
                  type="text"
                  id="companyProfile_contactName"
                  class="w-full"
                  pInputText
                  placeholder="Contact name"
                  formControlName="contactName"
                />
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactEmail" class="label">Contact email</label>
                <input
                  type="email"
                  id="companyProfile_contactEmail"
                  class="w-full"
                  pInputText
                  placeholder="Contact email"
                  formControlName="contactEmail"
                />
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactPhone" class="label">Contact phone</label>
                <input
                  type="tel"
                  id="companyProfile_contactPhone"
                  class="w-full"
                  pInputText
                  placeholder="Contact phone"
                  formControlName="contactPhone"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="mb-4">
          <h4 class="title4 mb-4">Advanced</h4>
          <div class="inputCont">
            <label for="companyProfile_termsUrl" class="label">Terms of service URL</label>
            <textarea
              id="companyProfile_termsUrl"
              formControlName="termsUrl"
              class="w-full"
              placeholder="https://example.url"
              pInputTextarea
            ></textarea>
          </div>
          <div class="inputCont">
            <label for="companyProfile_xmlDisabledMsg" class="label">XML disabled message</label>
            <textarea
              id="companyProfile_xmlDisabledMsg"
              formControlName="xmlDisabledMessage"
              class="w-full"
              placeholder="XML disabled message"
              pInputTextarea
            ></textarea>
          </div>

          <section class="card card_border card_border_danger p-0">
            <alert
              color="danger"
              [icon]="faExclamationCircle"
              label="Please be careful"
              description="The following settings are for advanced users only. Don't modify these settings unless you know what you're doing."
            >
            </alert>
            <div class="grid my-0 p-4 pb-0">
              <div class="col-6 xl:col-4 py-0">
                <div class="inputCont">
                  <label for="companyProfile_roofingWRXCompanyId" class="label">Roofing WRX Company ID</label>
                  <input
                    type="text"
                    id="companyProfile_roofingWRXCompanyId"
                    class="w-full"
                    pInputText
                    placeholder="Website"
                    formControlName="roofingWRXCompanyId"
                  />
                  <span
                    class="p-error"
                    *ngIf="roofingWRXCompanyId.invalid && (roofingWRXCompanyId.touched || roofingWRXCompanyId.dirty)"
                  >
                    The RoofingWRX company ID must be an integer number.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>

    <section class="flex align-items-center flex-column md:flex-row gap-3">
      <button pButton type="submit" class="fullMobile" (click)="save()">
        <fa-icon [icon]="faSave"></fa-icon>
        Update profile
      </button>
      <p class="text-sm font-semibold text-light">Unsaved changes</p>
    </section>
  </form>
</div>

<!-- <div class="col-12 xl:col-10" *ngIf="this.isRPAdmin || (manufacturers | async)?.length > 1">
  <div class="text-left p-4">
    <div class="form-label">Company</div>
    <p-dropdown
      style="width: 100%"
      id="manufacturerId"
      [options]="manufacturers | async"
      optionLabel="name"
      optionValue="id"
      formControlName="manufacturerId"
      [filter]="(manufacturers | async)?.length > 1"
      [disabled]="(manufacturers | async)?.length <= 1"
      (onChange)="onManufacturerChanged($event)"
    ></p-dropdown>
  </div>
</div> -->
