import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProfileService } from "src/app/shared/services/profile.service";
import { AuthContext, AuthenticationService } from "src/app/shared/services/authentication.service";
import { ConfirmService } from "../confirm/confirm.service";
import { Service } from "./service-details";
import { ThirdPartyAuth } from "../data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";
import { AppConstants } from "src/app/shared/constants/app-constants";

@Component({
  selector: "app-third-party-integrations",
  templateUrl: "./third-party-integrations.component.html",
})
export class ThirdPartyIntegrationsComponent {
  @Input() services: Service[] = [];
  @Output() mapServices: EventEmitter<any> = new EventEmitter();
  isAddAccountModalOpen: boolean = false;
  partnerSystem: string = null;
  authorization: ThirdPartyAuth;

  constructor(
    private profileService: ProfileService,
    private authService: AuthenticationService,
    private confirmService: ConfirmService,
    private appState: AppState
  ) {}

  openAddAccountModal(partnerSystem: string) {
    this.isAddAccountModalOpen = true;
    this.partnerSystem = partnerSystem;

    var authContext: AuthContext =
      this.partnerSystem == AppConstants.ThirdPartyServiceName.EagleView
        ? AuthContext.EagleView
        : this.partnerSystem == AppConstants.ThirdPartyServiceName.SmartBuild
          ? AuthContext.SmartBuild
          : this.partnerSystem == AppConstants.ThirdPartyServiceName.RoofingWRX
            ? AuthContext.RoofingWRX
            : AuthContext.None;

    this.authorization = this.appState.currentProfile.authorizations.find((x) => x.context === authContext);
  }

  logout(service: Service) {
    this.confirmService.confirm(
      "Logout",
      `Are you sure you want to logout of ${service.title}?`,
      "pi pi-question-circle",
      () => {
        this.profileService.clearTokens([service.context]).subscribe({
          next: () => {
            this.mapServices.emit();
            this.authService.logout(service.context);
          },
        });
      },
      () => {
        this.confirmService.close();
      }
    );
  }
}
